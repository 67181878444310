@import '../../global/style.scss';

.reference-page {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 100px;

    padding: 0px 2% 2% 2%;
    margin-top: 75px;

    &-body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 60px;
        width: 100%;

        &-up {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 50%;
            gap: 16px;

            &-title {
                @include desktop_heading_h1;
                letter-spacing: -0.01em;
                font-feature-settings: 'pnum' on, 'lnum' on, 'kern' off;
                color: $display_color;
            }

            &-content {
                @include desktop_body_l;
                color: $body_color;
            }

        }

        &-down {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
        }
    }

    &-footer {
        margin: 0% -2%;
        width: -webkit-fill-available;
    }

}

@media screen and (max-width: 870px) {
    .reference-page {
        gap: 30px;
        padding: 0px 3.5% 3.5% 3.5%;

        &-body {
            gap: 30px;

            &-up {
                gap: 12px;

                &-title {
                    @include mobile_heading_h1-h2;
                    letter-spacing: -0.01em;
                    font-feature-settings: 'pnum' on, 'lnum' on, 'kern' off;
                    color: $display_color;
                }

                &-content {
                    @include mobile_subTitle_S14;
                    color: $body_color;
                }

            }

            &-down {
                gap: 16px;
            }
        }

        &-footer {
            margin: 0% -3.5%;
        }
    }

}

@media screen and (max-width: 426px) {
    .reference-page {
        padding: 0px 5% 5% 5%;
        gap: 16px;

        &-footer {
            margin: 0% -5%;
        }

    }
}