@import '../../../global/style.scss';

.VideoLoading{
    display: none;
}

.CornerVideo {
    //position: absolute;
    position: sticky;
    bottom: 0px;
    right: 0px;
    align-content: center;
    width: 100%;
    height: 230px;
    max-width: 1600px;

    &-backgrnd {
        flex-wrap: wrap;
        align-content: center;
        z-index: 1000;
    }

    .VideFullscreen {
        height: 90dvh;
        overflow: hidden;
        object-fit: contain;
        
        &-video {
            height: 100%;
            object-fit: contain;
            //width: 90vw;
        }
    }

    .reducedSize {
        height: 180px;
    }

    &-wrap {
        position: absolute;
        bottom: 0px;
        right: 0px;
        height: 250px;
        width: fit-content;
        border: 20px solid rgba(255,255,255,.0);
        background-color: rgba(255,255,255,.4);
        border-radius: 10px;
        display: flex;
        align-content: end;

        &-arrows {
            z-index: 100;
            cursor: pointer;
            height: fit-content;
            width: fit-content;
            position: absolute;
            top: 7px;
            right: 7px;

            justify-content: center;
            padding: 5px;

            &-img {
                height: 30px;
            }
        }

        &-speaker {
            z-index: 100;
            cursor: pointer;
            height: fit-content;
            width: fit-content;
            position: absolute;
            top: 8px;
            left: 7px;

            &-imgOff {
                height: 35px;
            }

            &-imgOn {
                height: 35px;
                display: none;
            }

            .showIcon {
                display: unset;
            }

            .dontShowIcon {
                display: none;
            }
        }

        &-body {
            bottom: 0px;
            right: 25px;

            border: 2px solid $secondary_color;
            border-radius: 10px;
            height: 100%;
            //margin: 20px;

            &-small {
                height: 100%;
                border-radius: 10px;
            }
        }
    }

    &-wrap:hover {
        width: fit-content;
        height: 400px;
    }

    &-wrap-speaker:hover .showIcon{
        display: none;
    }

    &-wrap-speaker:hover .dontShowIcon{
        display: unset;
    }

    &-wrap-arrows-img:hover {
        height: 50px;
    }


}

// @media screen and (max-width: 870px) {
//     .CornerVideo {
//         .VideFullscreen {
//             height: fit-content;
//             &-video {
//                 width: 100%;
//             }
//         }
//     }
// }