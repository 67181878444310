@import '../../../global/style.scss';

.logo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 11px;
    white-space: nowrap;
    cursor: pointer;

    color: $display_color;

    img {
        height: 40px;
    }

    &-text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 160%;
    }

    .white {
        color: $bg_color;
    }
}

@media screen and (max-width: 900px) {
    .logo {
        &-text {
            display: none;
        }
    }
}