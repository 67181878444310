@import '../../../global/style.scss';

.tabButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // padding: 14px 20px;
    // gap: 6px;
    //cursor: pointer;
    //white-space: nowrap;
    gap:15px;
    padding-bottom: 20px;

    //background: $display_color;
    //border-radius: 38px;
    //color: $bg_color;

    &-button{
        cursor: pointer;
    }

    &-icon {
        margin: 0px 3px;
        transform: unset !important;
        pointer-events: none;
    }

    &-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
    }

    .activeButton {
        border-bottom: 2px solid black;
        opacity: 1;
        font-weight: 800;
    }
}

