@import '../../../global/style.scss';

.disabledBackground {

    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: gray;
    position: fixed;
    z-index: 999;
    left: 0px;
    top: 0px;
    background: rgba(0, 0, 0, 0.8);
    overflow: auto;
    justify-content: center;
    align-items: center;

    &-dialog {
        display: block;
        position: fixed;
        z-index: 1000;
        width: fit-content;
        height: fit-content;
        overflow-y: auto;
        background: $bg_color;
        //right: 20vw;
        //top: -150vh;
        transition: all 0.4s;
        border-radius: 24px;
        max-width: 800px;

        &-closeBtn {
            position: absolute;
            right: 16px;
            top: 16px;
            z-index: 1;
            cursor: pointer;
        }

    }

}

.hiddenDialog {
    position: absolute;
    top: -150vh;
}

@media screen and (max-width: 500px) {
    .disabledBackground {
        font-size: 3.2vw;
        align-items: start;

        &-line {
            position: relative;
            width: 30.54vw;

            &-1 {
                height: 1.41vw;
            }

            &-2 {
                height: 1.41vw;
            }
        }
    }
}