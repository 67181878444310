@import '../../global/style.scss';

.getpassport {
    display: flex;
    flex-direction: column;
    gap: 2.22vw;
    padding: 0px 8.3% 7.2% 8.3%;

    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-title {
            @include desktop_heading_h2;

            letter-spacing: -0.01em;
            font-feature-settings: 'cpsp' on;

            color: $display_color;
        }

        &-control {
            display: flex;
            gap: 4%;

            &-button {}
        }
    }

    &-content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2%;

        &-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 2.77vw;
            gap: 0.69vw;
            background-color: $gray_first_color;
            border-radius: 12px;

            &-img {
                img {
                    width: 100%;
                    padding: 2vw 2vw 1vw 2vw;
                }

            }

            &-title {
                @include desktop_subTitle_L;
                letter-spacing: -0.01em;
                font-feature-settings: 'cpsp' on;
                color: $display_color;
                align-self: flex-start;
            }

            &-desc {
                @include desktop_body_s;
                color: $body_color;
            }
        }
    }

    &-footer {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .getpassport {
        padding: 0px 3.5% 3.5% 3.5%;

        &-header {
            &-title {
                @include mobile_heading_h2-24;
            }

            &-control {
                &-button {
                    @include mobile_body_S12;
                }
            }
        }

        &-content {
            &-item {
                &-img {}

                &-title {
                    @include mobile_subTitle_S15;
                }

                &-desc {
                    @include mobile_subTitle_S12;
                }
            }
        }

        &-footer {
            display: none;
        }
    }
}

@media screen and (max-width: 426px) {
    .getpassport {
        padding: 0px 5% 5% 5%;

        &-header {
            &-control {
                display: none;
            }
        }

        &-content {
            grid-template-columns: repeat(1, 1fr);
            gap: 0%;

            &-item {
                &:not(:first-child) {
                    display: none;
                }
            }
        }

        &-footer {
            width: 50%;
            display: inline-block;
            margin-top: 10px;
            @include mobile_body_S12;
            color: $bg_color;
        }
    }
}