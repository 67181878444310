@import '../../global/style.scss';

.step-content4 {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1.38vw;
    padding: 3% 2%;
    margin-bottom: 8.33vw;

    &-left {
        flex: 29.8%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2px;

        &-photos {
            box-sizing: border-box;
            border: 2px solid $main_color;
            border-radius: 12px;

            display: flex;
            flex-direction: row;
            align-items: center;

            &-item {
                display: flex;
                flex-direction: column;
                align-items: center;

                img {
                    border-radius: 12px;
                    width: 100%;
                }
            }

        }

        &-arrow {
            align-self: flex-end;
            width: 47%;
        }
    }

    &-mid {
        flex: 40.3%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 0.83vw;
        gap: 1.38vw;

        &-img {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border: 2px solid #F5F5F5;
            border-radius: 0.83vw;
            position: relative;

            &-photo {
                width: 100%;
            }

            &-arrow {
                display: none;
            }
        }
    }

    &-right {
        flex: 29.8%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        white-space: nowrap;

        &-up {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 40px;
            gap: 20px;
            width: 100%;

            background: $gray_first_color;
            border-radius: 12px;

            &-title {
                @include desktop_subTitle_L;
                color: $display_color;
            }

            &-content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 0.83vw;

                &-item {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    &-title {
                        @include desktop_body_s;
                        color: $body_color;
                    }

                    &-content {
                        @include desktop_body_s_bold;
                        color: $display_color;
                    }
                }
            }
        }

        &-down {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 40px;
            gap: 20px;
            width: 100%;

            background: $gray_first_color;
            border-radius: 12px;

            &-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 4px;
                width: 100%;

                &-text {
                    @include desktop_body_s;
                    color: $body_color;
                }
            }
        }

        &-button {
            width: 100%;
        }

    }
}

@media screen and (max-width: 768px) {
    .step-content4 {
        &-right {
            gap: 16px;

            &-up {
                padding: 20px;
                gap: 16px;

                &-title {
                    @include mobile_heading_h3-20;
                }

                &-content {
                    &-item {
                        &-title {
                            @include mobile_body_S12;
                        }

                        &-content {
                            @include mobile_subTitle_S14_bold;
                        }
                    }
                }
            }

            &-down {
                padding: 20px;

                &-content {
                    &-text {
                        @include mobile_body_S12;
                    }
                }
            }

            &-button {
                @include mobile_body_S12;
            }
        }

        &-mid {}
    }
}

@media screen and (max-width: 500px) {
    .step-content4 {
        flex-direction: column-reverse;
        gap: 16px;

        &-left {
            &-photos {
                width: 100%;

                &-item {
                    flex: 50%;
                }
            }

            &-arrow {
                display: none;
            }
        }

        &-mid {
            &-img {
                &-arrow {
                    display: block;
                    position: absolute;
                    top: 76vw;
                    height: 22.6vw;
                }
            }
        }
    }
}