@import '../../global/style.scss';

.instructions {
    display: flex;
    flex-direction: column;

    &-step {

        display: flex;
        flex-direction: row;

        &-text {
            width: 70%;

            &-subTitle {
                @include desktop_subTitle_S20;
            }
        
            &-body {
                padding-left: 15px;
                @include desktop_subTitle_S18;
                line-height: 150%;
        
                ul {
                    padding-inline-start: 20px;
                }
            }
        }

        &-img {

            &-zoom {
                display: none;
                width: 90%;
            }
            
            width: 30%;
            img {
                width: 100%;
                align-items: end;
                border: gray;
                border-style: solid;
                border-width: thin;
                border-radius: 10px;
            }
        }
    
    }

    &-title {
        @include desktop_subTitle_L_32;
        padding-bottom: 20px;
    }
}

@media screen and (max-width: 600px) {
    .instructions {
        &-step {
            display: flex;
            flex-direction: column;

            &-text {
                width: 100%;

                &-subTitle {
                    @include mobile_subTitle_S18;
                }
                &-body {
                    @include mobile_body_S16;
                }
            }

            &-img {
                padding-top: 8px;
                padding-bottom: 8px;
                width: 100%;
            }
        }       

        &-title {
            @include mobile_heading_h2-24;
            padding-bottom: 15px;
        }
    }
}