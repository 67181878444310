/* Brand color*/
$main_color: #1F8498;
$secondary_color: #ABE2ED;
$minor_color: #FFF0BC;

/* Brand Tone */
$tone_first_color: #D5F0F6;
$tone_second_color: #EEF9FB;

/* Gray */
$gray_first_color: #F5F5F5;
$gray_second_color: #E6E6E6;
$gray_third_color: #D1D1D1;
$gray_fourth_color: #AAAAAA;

/* Basic color */
$display_color: #182335;
$body_color: rgba(#182325, 0.72);
$bg_color: #FFFFFF;

/* Accent color */
$error_color: #DF2727;
$error100_color: #FCE9E9;
$success_color: #05AE5D;
$success100_color: #E6F7EF;
$warning_color: #FFA800;
$warning100_color: #FFF6E6;

/* Desktop font */

@mixin desktop_heading_h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 68px;
    line-height: 130%;
}

@mixin desktop_heading_h1-h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 58px;
    line-height: 130%;
}

@mixin desktop_heading_h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 52px;
    line-height: 130%;
}

@mixin desktop_heading_h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 130%;
}

@mixin desktop_subTitle_L_40 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 130%;
}

@mixin desktop_subTitle_L_32 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 42px;
}

@mixin desktop_subTitle_L_24 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
}

@mixin desktop_subTitle_L {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 150%;
}

@mixin desktop_subTitle_S20 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 180%;
}

@mixin desktop_subTitle_S18 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 130%;
}

@mixin desktop_body_l {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
}

@mixin desktop_body_S19 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 200%;
}

@mixin desktop_body_m {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 180%;
}

@mixin desktop_body_s {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
}

@mixin desktop_body_s_bold {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
}

@mixin desktop_body_ss {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
}

@mixin desktop_body_S13 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 180%;
}

@mixin desktop_body_S12 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}

/* Mobile font */
@mixin mobile_heading_h1-38 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 38px;
    line-height: 130%;
}

@mixin mobile_heading_h1-h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 130%;
}

@mixin mobile_heading_h2-24 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 130%;
}

@mixin mobile_heading_h2-22 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 130%;
}

@mixin mobile_heading_h3-20 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
}

@mixin mobile_subTitle_S18 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
}

@mixin mobile_body_S16 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
}

@mixin mobile_subTitle_S15 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 150%;
}

@mixin mobile_subTitle_S14 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;
}

@mixin mobile_subTitle_S14_bold {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
}

@mixin mobile_subTitle_S14_bold_b {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
}

@mixin mobile_body_S12 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
}

@mixin mobile_body_S12_medium {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
}

@mixin mobile_body_S12_light {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
}

@mixin mobile_body_S11 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 150%;
}

@mixin mobile_body_S10 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 150%;
}

@mixin mobile_subTitle_S12 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 180%;
}

@mixin mobile_body_S8 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 150%;
}

$main_color: #1F8498;
$secondary_color: #ABE2ED;
$minor_color: #FFF0BC;

/* Brand Tone */
$tone_first_color: #D5F0F6;
$tone_second_color: #EEF9FB;

/* Gray */
$gray_first_color: #F5F5F5;
$gray_second_color: #E6E6E6;
$gray_third_color: #D1D1D1;
$gray_fourth_color: #AAAAAA;

/* Basic color */
$display_color: #182335;
$body_color: rgba(#182325, 0.72);
$bg_color: #FFFFFF;

/* Accent color */
$error_color: #DF2727;
$error100_color: #FCE9E9;
$success_color: #05AE5D;
$success100_color: #E6F7EF;
$warning_color: #FFA800;
$warning100_color: #FFF6E6;

/* Desktop font */

@mixin desktop_heading_h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 68px;
    line-height: 130%;
}

@mixin desktop_heading_h1-h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 58px;
    line-height: 130%;
}

@mixin desktop_heading_h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 52px;
    line-height: 130%;
}

@mixin desktop_heading_h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 130%;
}

@mixin desktop_subTitle_L_40 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 130%;
}

@mixin desktop_subTitle_L_24 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
}

@mixin desktop_subTitle_L {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 150%;
}

@mixin desktop_subTitle_S20 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 180%;
}

@mixin desktop_body_l {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
}

@mixin desktop_body_S19 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 200%;
}

@mixin desktop_body_m {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 180%;
}

@mixin desktop_body_s {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
}

@mixin desktop_body_s_bold {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
}

@mixin desktop_body_ss {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
}

@mixin desktop_body_S13 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 180%;
}

@mixin desktop_body_S12 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 180%;
}

/* Mobile font */
@mixin mobile_heading_h1-38 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 38px;
    line-height: 130%;
}

@mixin mobile_heading_h1-h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 130%;
}

@mixin mobile_heading_h2-24 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 130%;
}

@mixin mobile_heading_h2-22 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 130%;
}

@mixin mobile_heading_h3-20 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
}

@mixin mobile_subTitle_S18 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
}

@mixin mobile_body_S16 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
}

@mixin mobile_subTitle_S15 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 150%;
}

@mixin mobile_subTitle_S14 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;
}

@mixin mobile_subTitle_S14_bold {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
}

@mixin mobile_subTitle_S14_bold_b {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
}

@mixin mobile_body_S12 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
}

@mixin mobile_body_S12_medium {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
}

@mixin mobile_body_S12_light {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
}

@mixin mobile_body_S11 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 150%;
}

@mixin mobile_body_S10 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 150%;
}

@mixin mobile_subTitle_S12 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 180%;
}

@mixin mobile_body_S8 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 150%;
}

@font-face {
    font-family: poppins;
    font-style: normal;
    font-weight: 500;
    src: local('Poppins'), url(../assets/fonts/Poppins-Medium.woff) format('woff');
    font-display: swap;
}

@font-face {
    font-family: poppins;
    font-style: normal;
    font-weight: 400;
    src: local('Poppins'), url(../assets/fonts/Poppins-Regular.woff) format('woff');
    font-display: swap;
}

@font-face {
    font-family: poppins;
    font-style: normal;
    font-weight: 600;
    src: local('Poppins'), url(../assets/fonts/Poppins-SemiBold.woff) format('woff');
    font-display: swap;
}
