@import '../../global/style.scss';

.privacy {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 36px;

    &-title {
        @include desktop_heading_h2;
        letter-spacing: -0.01em;
        font-feature-settings: 'cpsp' on;
        color: $display_color;
    }

    &-text {
        @include desktop_body_S19;
        color: $body_color;

        span.link {
            color: $main_color;
            text-decoration: underline;
        }

        span.bold {
            font-weight: 200%;
        }

        span.italic {
            font-style: italic;
        }
    }

    &-group {
        &-title {
            @include desktop_heading_h3;
            letter-spacing: -0.01em;
            font-feature-settings: 'cpsp' on;
            color: $display_color;
        }

        &-text {
            @include desktop_body_S19;
            color: $body_color;

            span.link {
                color: $main_color;
                text-decoration: underline;
            }

            span.bold {
                font-weight: 200%;
            }

            span.italic {
                font-style: italic;
            }
        }
    }

}

@media screen and (max-width: 768px) {
    .privacy {

        &-title {
            @include mobile_heading_h2-24;
            letter-spacing: -0.01em;
            font-feature-settings: 'cpsp' on;
            color: $display_color;
        }

        &-text {
            @include mobile_subTitle_S14;
            color: $body_color;
        }

        &-group {
            &-title {
                @include mobile_heading_h3-20;
                letter-spacing: -0.01em;
                font-feature-settings: 'cpsp' on;
                color: $display_color;
            }

            &-text {
                @include mobile_subTitle_S14;
                color: $body_color;
            }
        }
    }
}