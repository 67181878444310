@import '../../../global/style.scss';

.price-panel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;
    gap: 32px;

    background-color: $tone_first_color;
    border-radius: 24px;

    &-badge {
        @include desktop_body_l;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        gap: 10px;

        background: $main_color;
        border-radius: 100px;
        color: $bg_color;
        white-space: nowrap;
    }

    &-show {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 5px;

        &-value {
            @include desktop_heading_h1;

            display: flex;
            align-items: center;
            letter-spacing: -0.01em;
            font-feature-settings: 'pnum' on, 'lnum' on, 'kern' off;

            color: $main_color;
        }

        &-preValue {
            @include desktop_heading_h3;
            color: $main_color;
            text-decoration: line-through;
        }
    }

    &-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;

        &-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;

            &-text {
                @include desktop_body_s;
                color: $display_color;
            }
        }
    }

    &-button {
        width: 100%;
    }
}

.black {
    background-color: $main_color;

    .price-panel {
        &-badge {
            background: $tone_first_color;
            color: $display_color;
        }


        &-show {
            &-value {
                color: $bg_color;
            }

            &-preValue {
                color: $bg_color;
            }
        }

        &-content {
            &-item {
                &-text {
                    color: $bg_color;
                }
            }
        }
    }

    .right-badge {
        align-self: flex-end;
        background: $minor_color;
        color: $main_color;
        margin: -32px;
    }
}

@media screen and (max-width: 900px) {
    .price-panel {
        padding: 40px 20px;
        gap: 24px;

        &-badge {
            padding: 6px 16px;
            @include mobile_subTitle_S14_bold;
        }

        .right-badge {
            margin: -32px -12px -12px -12px;
        }

        &-show {
            &-value {
                @include mobile_heading_h1-h2;
            }

            &-preValue {
                @include mobile_heading_h2-22;
            }
        }

        &-content {
            @include mobile_body_S16;
        }

        &-button {
            @include mobile_body_S12;
        }

    }
}

@media screen and (max-width: 600px) {
    .price-panel {
        &-button {
            @include mobile_subTitle_S14_bold_b;
        }
    }
}