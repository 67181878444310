@import '../../../global/style.scss';

.adminDashboard {
    display: flex;
    flex-direction: column;
    justify-content: left;

    &-data {
        display: flex;
        flex-direction: column;

        &-row {
            display: flex;
            flex-direction: row;

            &-month {
                width: 100px;
            }
    
        }
    }


}