@import '../../global/style.scss';

.howtomake2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2.22vw;
    padding-bottom: 7%;

    &-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        //width: 100%;

        &-title {
            @include desktop_heading_h2;

            letter-spacing: -0.01em;
            font-feature-settings: 'cpsp' on;

            color: $display_color;
            //padding-left: 24px;
        }
    }

    &-content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1%;
        z-index: -1;

        &-item {
            //background-color: $tone_second_color;
            border-radius: 12px;
            position: relative;
            padding: 30px 24px;
            display: flex;
            flex-direction: column;

            &-badge {
                @include desktop_body_l;
                position: absolute;
                top: 8px;
                left: 8px;
                background-color: $display_color;
                color: $bg_color;
                border-radius: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 46px;
                height: 46px;
            }

            &-image {
                width: 100%;
                //margin-top: 45px;
                margin-bottom: 30px;
                display: flex;
                flex-direction: row;
                align-items: center;
                //max-height: 160px;
                
                &-edges {
                    width: 100%;
                    height: 20px;
                    margin-left: 5px;
                    margin-right: 5px;
                    border-left: 1px dotted black;
                    border-right: 1px dotted black;

                        &-middleLine {
                            display: block;
                            width: 94%;
                            height: 50%;
                            margin-left: 3%;
                            margin-right: 3%;
                            border-bottom: 1px dotted black;
                        }
                    }

                &-arrow-right {
                    width: 0; 
                    height: 0; 
                    border-top: 20px solid transparent;
                    border-bottom: 20px solid transparent;
                    border-left: 20px solid #ccc;
                    float:right;
                    top: 50%;
                    //margin-top:-20px;
                    //margin-right:-40px;
                }

                &-arrow-left {
                    width: 0; 
                    height: 0; 
                    border-top:40px solid transparent;
                    border-bottom: 40px solid transparent; 
                    float:left;
                    border-right:40px solid #ccc;
                    //margin-top:-20px;
                    //margin-left:-40px;
                }

                div {
                    display: flex;
                    flex-direction: column;
                    width: 50%;
                    height: 100%;
                    //flex-wrap: wrap-reverse;
                    text-align: center;
                    align-content: center;
                    align-items: center;
                    justify-content: center;
                    //font-size: 14pt;
                    // letter-spacing: -0.5em;
                    // font-weight: 10;
                    img {
                        width: 100%    
                    }
                }

                img {
                    //border-radius: 8px;
                    width: 50%
                }
            }

            &-title {
                @include desktop_subTitle_S18;
                letter-spacing: -0.01em;
                font-feature-settings: 'cpsp' on;
                color: $display_color;
            }

            &-desc {
                @include desktop_body_s;
                color: $body_color;
                margin-top: 8px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .howtomake2 {
        padding-bottom: 3.5%;


        &-header-title {
            @include mobile_heading_h2-24;
        }

        &-content {
            grid-template-columns: repeat(2, 1fr);

            &-item {


                &:last-child {
                    width: 202%;
                }

                &-badge {
                    @include mobile_subTitle_S18;
                    width: 40px;
                    height: 40px;
                }

                &-title {
                    @include mobile_heading_h3-20;
                }

                &-desc {
                    @include mobile_subTitle_S14;
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .howtomake2 {
        //padding: 0px 5% 5% 5%;
        padding-bottom: 3.5%;

        &-header-title {
            @include mobile_heading_h2-22;
            //margin-top: 60px;
            margin-bottom: 24px;
            padding-left: 0;
        }

        &-content {
            grid-template-columns: repeat(1, 1fr);

            &-item {
                padding: 30 0;

                &-image {
                    div {
                        font-size: 10pt;
                    }
                }

                &:last-child {
                    width: 100%;
                }

                &-title {
                    @include mobile_subTitle_S15;
                }

            }
        }
    }
}