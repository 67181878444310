@import '../../../global/style.scss';

.errorList {
    display: flex;
    flex-direction: column;
    justify-content: left;

    span {
        font-weight: 800;
    }

    &-errorDetails {
        padding-left: 50px;
    }
}