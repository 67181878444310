@import '../../global/style.scss';

.info {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 1.38vw;
    padding: 0px 8.3vw 7.2vw 8.3vw;
    margin-top: -6vw;

    &-left {
        flex: 50%;
        background-color: $gray_first_color;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 2.77vw;
        gap: 1.94vw;

        &-title {
            @include desktop_heading_h2;

            letter-spacing: -0.01em;
            font-feature-settings: 'cpsp' on;

            color: $display_color;
        }

        &-content {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 1.38vw;
            width: 100%;

            &-item {
                flex: 50%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 4px;

                .value {
                    @include desktop_heading_h2;

                    letter-spacing: -0.01em;
                    font-feature-settings: 'cpsp' on;

                    color: $main_color;
                }

                .desc {
                    @include desktop_body_m;

                    color: $body_color;

                }
            }
        }
    }

    &-right {
        flex: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        gap: 1.38vw;

        &-item {
            background-color: $gray_first_color;
            border-radius: 12px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 2.77vw;
            gap: 0.83vw;
            height: -webkit-fill-available;
            max-height: fit-content;
            @include desktop_subTitle_L;
            letter-spacing: -0.01em;
            font-feature-settings: 'cpsp' on;
            color: $display_color;

            img {
                width: 52px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .info {
        padding: 0px 3.5% 3.5% 3.5%;

        &-left {
            &-title {
                @include mobile_heading_h2-24;
            }

            &-content {
                &-item {
                    .value {
                        @include mobile_heading_h2-24;
                    }

                    .desc {
                        @include mobile_subTitle_S14;
                    }
                }
            }
        }

        &-right {
            &-item {
                @include mobile_subTitle_S14;
            }
        }
    }
}

@media screen and (max-width: 426px) {
    .info {
        padding: 0px 5% 5% 5%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 0.83vw;

        &-left {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 0.83vw;
            width: 100%;
        }

        &-right {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.83vw;

            &-item {
                width: -webkit-fill-available;
                height: fit-content;
            }

        }
    }
}