@import '../../global/style.scss';

.drag {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 12px 12px 8px;
    gap: 14px;

    background: $gray_first_color;

    border: 2px dashed $main_color;
    box-shadow: 8px 8px 27px rgba(31, 152, 102, 0.25);
    border-radius: 200px;
    z-index: 50;

    &-text {
        @include mobile_subTitle_S14;
        background: none;
        border: none;
        width: 300px;
        pointer-events: none;
        display: block;
        text-align: right;
    }

    &-textM {
        @include desktop_body_s;
        background: none;
        border: none;
        width: 95px;
        pointer-events: none;
        display: block;
        text-align: right;
    }

    &-button {
        text-align: center;
        font-size: 16px;
        min-width: 152px;
    }
}

@media screen and (max-width: 768px) {
    .drag {
        &-text {
            width: 300px;
            @include mobile_subTitle_S14;
        }

        &-button {
            font-size: 14px;
        }
    }
}

@media screen and (max-width: 500px) {
    .drag {
        &-text {
            text-align: right;
            width: 84px;
        }

        &-button {
            min-width: 148px;
        }
    
    }
}