@import '../../global/style.scss';

.rob {
    display: flex;
    flex-direction: column;
    gap: 2.22vw;
    padding: 0px 2%;

    &-up {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &-button {}

        &-title {
            @include desktop_heading_h2;
            letter-spacing: -0.01em;
            font-feature-settings: 'cpsp' on;
            font-size: 3.8vw;
        }
    }

    &-down {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.7vw;
        width: 100%;

        &-blog-group {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            gap: 2%;
            flex-wrap: wrap;

            .blog {
                flex: 1 0 30%;

                &:nth-child(4n) {
                    flex: 0 1 100%;
                }
            }
        }
    }

    &-button {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .rob {
        padding: 0px 3.5% 3.5% 3.5%;

        &-up {
            &-button {
                @include mobile_body_S12;
                padding: 10px 21px;
            }

            &-title {
                font-size: 3.2vw;

            }
        }
    }
}

@media screen and (max-width: 500px) {
    .rob {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1.66vw;
        padding: 0px 5% 5% 5%;

        &-up {
            &-button {
                display: none;
            }

            &-title {
                font-size: 7.5vw;
            }
        }

        &-down {
            &-blog-group {
                .blog {
                    display: none;

                    &:nth-child(1),
                    &:nth-child(2) {
                        display: block;
                    }
                }
            }
        }

        &-button {
            // margin-top: 60px;
            display: block;
            width: 50%;

            &-class {
                font-size: 3.75vw;
                padding: 1.5vw 3vw;
            }
        }
    }
}