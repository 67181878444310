@import '../../../global/style.scss';

.session {
    display: flex;
    flex-direction: column;
    justify-content: left;

    &-collapsed {
        display: flex;
        flex-direction: row;

        .passedChecks {
            background-color: aquamarine;
        }

        .isOkToDelete {
            border: 3px;
            border-style: solid;
            border-color: darkgreen;
            border-radius: 5px;
        }

        .notThisError {
            background-color: white;
            opacity: 0;
        }

        &-icons {
            width: 30px;
            display: flex;
            flex-direction: row;

            &-paid {
                width: 10px;
            }

            &-video {
                width: fit-content;
            }
        }

        &-timestamp {
            width: 180px;
            cursor: pointer;
        }

        &-error {
            width: 20px;

            &-tooltip {
                position: absolute;
                padding-left: 25px;
                opacity: 0;
            }
        }

        &-error:hover &-error-tooltip {
            opacity: 1;
        }

        &-error:nth-of-type(3) {
            background-color: red;
        }

        &-error:nth-of-type(4) {
            background-color: darkmagenta;
        }

        &-error:nth-of-type(5) {
            background-color: chocolate;
        }

        &-error:nth-of-type(6) {
            background-color: hotpink;
        }

        &-error:nth-of-type(7) {
            background-color: orchid;
        }

        &-error:nth-of-type(8) {
            background-color: orange;
        }

        &-promoCode {
            width: 250px;
            display: flex;
            justify-content: flex-end;
            
            &-value {
                @include desktop_body_s_bold;
            }
        }
    }

    .showMoreInfo {
        height: fit-content;
    }

    &-bottomInfo {
        display: flex;
        flex-direction: row;
        height: fit-content;
        padding: 5px 0 5px 5px;
        margin: 5px 0 5px 25px;
        background-color: antiquewhite;
        border-radius: 10px;

        img {
            height: 150px;
        }

        &-left-errDetails {
            padding-left: 20px;
        }

        &-right {
            display: flex;
            flex-direction: row;
        }
    }
}