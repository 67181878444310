@import '../../global/style.scss';

.promoCode {
    &-main {
        display: flex;
        flex-direction: row;

        &-button {
            @include desktop_subTitle_S18;
            margin-left: 5px;
            padding: 4px 20px;
            background: gray;
            border-radius: 12px;
            color: $bg_color;
            min-width: fit-content;
        }

        &-label {
            @include mobile_body_S16;
        }

        &-code {
            @include mobile_subTitle_S18;
            padding: 0px 5px 0px 5px;
        }

        &-link {
            @include mobile_body_S16;
            color: $main_color;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    &-error {
        @include mobile_body_S16;
        color: red;
        height: 20px;
    }

}

@media screen and (max-width: 768px) {
    .promoCode {   
    }
}

@media screen and (max-width: 426px) {
    .promoCode {
    }
}