@import '../../../global/style.scss';

.button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 20px;
    gap: 6px;
    cursor: pointer;
    white-space: nowrap;

    background: $display_color;
    border-radius: 38px;
    color: $bg_color;

    &-icon {
        margin: 0px 3px;
        transform: unset !important;
        pointer-events: none;
    }

    &-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
    }
}

.default {
    background: $display_color;
    color: $bg_color;
}

.header_white {
    background: $bg_color;
    color: $display_color;
    border: 1px solid #AAAAAA;
}

.minor {
    background: $minor_color;
    color: $display_color;
}

.tone2 {
    background: $tone_second_color;
    color: $main_color;
}

.brand {
    background: $main_color;
    color: $bg_color;
}

.tone_first_color {
    background: $tone_first_color;
    color: $display_color;
}

.secondary_color {
    background: $tone_first_color;
    color: $main_color;
}

.button-bg-white {
    background-color: $bg_color;
    color: $main_color;
}

.button-gray-white {
    background-color: $gray_first_color;
    color: $display_color;
}

.disable {
    background-color: $gray_second_color;
    color: $body_color;
}

.hide {
    display: none;
}

.padding-m {
    padding: 8px 16px;
}