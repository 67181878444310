@import '../../global/style.scss';

body:has(.disabledBackground) {
    overflow: hidden;
}

.dashboard {
    display: flex;
    flex-direction: column;
    //gap: 5.33vw;
    overflow: 'auto';

    .uploadButton {
        border: 2px dashed #1F8498;
        width: 200px;
        height: 100px;
        position: sticky;
        top: 0px;
    }

    &-alert {
        display: block;
        position: fixed;
        z-index: 1001;
        width: 46vw;
        height: fit-content;
        background: $bg_color;
        color: $error_color;
        right: 27vw;
        top: -150vh;
        transition: all 0.4s;
        border-radius: 10px;
        padding: 20px;
        text-align: center;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .active:has(.dashboard-dialog-content-content-right) {
        top: 1vh;
    }

    .active {
        top: 15vh;
        position: absolute;
    }

    &-dragbg {
        display: none;
        @include desktop_heading_h1-h2;
        color: $bg_color;
        text-align: center;
        flex-direction: column;
        justify-content: center;
    }

    .MainContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 30px;
        isolation: isolate;

        &-title {
            @include desktop_heading_h3;
            letter-spacing: -0.01em;
            font-feature-settings: 'cpsp' on;
            color: $display_color;

            &-discount {
                @include desktop_body_S12;
                letter-spacing: -0.02em;
                color: $main_color;
            }
        }

        &-subtitle {
            text-align: center;

            &-quotes {
                width: 600px;
            }
        }

        &-content {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 14px 0px 0px 0px;
            gap: 10px;

            &-left {
                padding: 16px;
                background: $bg_color;
                box-shadow: 2px 2px 24px rgba(0, 0, 0, 0.1);
                border-radius: 17px;
                display: flex;

                &-content {
                    position: relative;

                    &-disable-background {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        gap: 0.5vw;
                        align-items: center;
                        background: rgba(31, 132, 152, 0.7);
                        backdrop-filter: blur(10px);
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        border-radius: 12px;
                        @include desktop_body_s;
                        color: $bg_color;
                        font-size: 1.2vw;

                        &-line {
                            position: relative;
                            width: 10.54vw;

                            &-1 {
                                position: absolute;
                                width: 100%;
                                height: 0.41vw;
                                background: #FFFFFF;
                                opacity: 0.32;
                                border-radius: 2.08vw;
                            }

                            &-2 {
                                width: 0%;
                                height: 0.41vw;
                                background: #FFFFFF;
                                border-radius: 2.08vw;
                                animation-name: analyze1;
                                animation-duration: 30s;
                                animation-iteration-count: infinite;
                            }

                            @keyframes analyze1 {
                                100% {
                                    width: 10.54vw;
                                }
                            }
                        }
                    }

                    &-upload {
                        width: 100%;
                        max-width: 300px;
                        height: auto;
                        border-radius: 12px;
                        width: 22.7vw;
                        border: 3px solid #F8F8F8;
                    }
                }
            }

            &-right {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 0.55vw;

                ul {
                    margin-top: 0px;
                    margin-bottom: 0px;
                }

                input[type="checkbox"] {
                    margin-top: 8px;
                    margin-right: 5px;
                    margin-left: 10px;
                    transform: scale(1.5);
                }

                &-criteria {
                    display: none;
                    @include desktop_body_ss;
                    color: rgba(24, 35, 37, 0.72);

                    img {
                        width: 13px;
                        margin-left: 5px;
                    }
                }

                &-item {
                    align-self: flex-start;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    gap: 0.55vw;
                    border-radius: 15px;



                    &-img {
                        width: 28px;
                        height: 28px;
                    }

                    .redBackGround {
                        //background-color: pink;
                        animation-name: highlightBackground;
                        animation-duration: 3s;
                        animation-iteration-count: 1;
                    }

                    @keyframes highlightBackground {
                        0% {
                            background-color: pink;
                        }

                        100% {
                            background-color: white;
                        }
                    }


                    .visibleAsterix {
                        opacity: 1;
                    }

                    &-asterix {
                        color: darkred;
                        opacity: 0;
                    }

                    &-asterix:nth-child(3) {
                        margin-left: 22px;
                    }

                    &-text {
                        @include desktop_body_s;
                        border-radius: 5px;


                    }
                }

                &-pay {
                    margin-top: 1.25vw;
                    @include desktop_body_s;

                    span {
                        @include desktop_body_m;
                        color: $main_color;
                    }
                }

                &-btn {
                    border-radius: 12px;
                    width: 100%;
                }
            }
        }

        &-error {
            text-align: center;
            padding: 0px 0px 14px 0px;

            img {
                height: 300px;
                width: 300px;
            }

            &-upload {
                display: -webkit-inline-box;
                text-align: center;
            }

            &-link {
                display: none;
                padding-top: 7px;
            }

            .showLink {
                display: block;
            }
        }
    }

    &-file-upload {
        display: none;
    }

    &-bottom {
        height: 80px;

        margin-top: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        &-upload {
            width: fit-content;
        }
    }
}

.dragActive {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    border: 5px dashed #FFFFFF;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 1000;
    display: flex;
}

@media screen and (max-width: 768px) {
    .dashboard {
        .MainContent {
            padding: 15px 15px;

            &-title {
                @include mobile_heading_h2-24;
            }

            &-subtitle {
                &-quotes {
                    width: auto;
                }
            }

            &-content {
                &-left {
                    &-content {
                        &-upload {
                            width: 34.05vw;
                        }
                    }
                }

                &-right {

                    input[type="checkbox"] {
                        margin-top: 4px;
                    }

                    ul {
                        @include mobile_subTitle_S14;
                    }

                    &-item {
                        &-img {
                            width: 20px;
                            height: 20px;
                        }

                        &-text {
                            @include mobile_subTitle_S14;
                        }
                    }
                }
            }

            &-button {
                @include mobile_subTitle_S14_bold_b;
            }
        }
    }
}

@media screen and (max-height: 500px) {
    .dashboard {
        .active {
            top: 1vh;
            position: absolute;
        }
    }
}

@media screen and (max-width: 500px) {
    .dashboard {
        .MainContent {
            padding: 20px 15px;

            &-title {
                font-size: 19px;
            }

            //padding: 14vw 7vw 7vw 7vw;

            &-subtitle {
                text-align: center;

                &-quotes {
                    width: auto;
                }
            }

            &-content {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 6px;

                &-left {
                    &-content {
                        flex-direction: column;

                        &-disable-background {
                            font-size: 3.2vw;

                            &-line {
                                position: relative;
                                width: 30.54vw;

                                &-1 {
                                    height: 1.41vw;
                                }

                                &-2 {
                                    height: 1.41vw;
                                }
                            }
                        }

                        &-upload {
                            width: 74.6vw;
                        }
                    }
                }


                &-right {
                    gap: 0px;
                    width: 100%;

                    ul {
                        @include mobile_subTitle_S14;
                    }

                    &-item {
                        margin-left: 30px;

                        &-text {
                            padding-top: 6px;
                        }
                    }

                    &-criteria {
                        display: block;
                    }

                    &-btn {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}