@import '../../global/style.scss';

.step-content1 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0%;
    padding: 3% 2%;
    margin-bottom: 8.33vw;

    &-img {
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
            width: 100%;
            transform: matrix(-1, 0, 0, 1, 0, 0);
            padding: 0vw 5vw;
        }
    }

    &-card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 2.7vw;
        gap: 2.2vw;
        white-space: nowrap;
        background: $gray_first_color;
        border-radius: 0.83vw;

        &-title {
            @include desktop_subTitle_L;
            letter-spacing: -0.01em;
            font-feature-settings: 'cpsp' on;
            color: $display_color;
        }

        &-item-group {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 1.3vw;
            width: 100%;


            &-item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 0.27vw;

                width: 100%;

                &-label {
                    @include desktop_body_s;
                    display: flex;
                    align-items: center;
                    color: $body_color;
                }

                &-content {
                    width: 100%;
                    padding: 16px;
                    border-radius: 8px;
                    white-space: nowrap;
                    min-width: max-content;
                    background-color: $bg_color;

                    @include desktop_body_m;
                    display: flex;
                    align-items: center;
                    letter-spacing: -0.408px;
                    color: $body_color;
                }
            }
        }

        &-button {
            text-align: center;
            width: 100%;
        }

    }
}

@media screen and (max-width: 768px) {
    .step-content1 {
        &-card {
            &-title {
                @include mobile_heading_h3-20;
            }

            &-item-group {
                &-item {
                    &-label {
                        @include mobile_subTitle_S14;
                    }

                    &-content {
                        padding: 11px 16px;

                        @include mobile_body_S12_light;
                    }
                }
            }

            &-button {
                @include mobile_body_S12;
            }

        }
    }
}

@media screen and (max-width: 500px) {
    .step-content1 {
        display: flex;
        flex-direction: column-reverse;
        align-items: stretch;
        gap: 2vw;
    }
}