@import '../../global/style.scss';

.edit {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 3% 13.19%;
    margin-top: 85px;
    gap: 2.22vw;

    &-up {
        text-align: center;

        &-title {
            @include desktop_heading_h3;
            letter-spacing: -2px;
            color: $display_color;
        }

        &-subtitle {
            @include desktop_body_ss;
            color: rgba(24, 35, 37, 0.72);

            span {
                color: $main_color;
                text-decoration: underline;
            }
        }
    }

    &-down {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        width: 100%;
        gap: 1.38vw;

        &-left {
            flex: 58%;
            background: rgba(18, 18, 18, 0.75);
            border-radius: 8px;
            padding: 3.1vw 7.2vw;
            flex-direction: row;
            align-items: center;
            display: flex;

            &-img {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                border: 2px solid #F5F5F5;
                border-radius: 0.83vw;
                position: relative;
                width: 100%;
                background-color: $bg_color;

                &-photo {
                    width: 100%;
                    border-radius: 0.83vw;
                }

                &-more {
                    align-self: flex-end;
                    position: absolute;
                    margin: 0.55vw;
                    width: 56px;
                    height: 56px;
                }

                &-crop {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                }
            }

        }

        &-right {
            flex: 42%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 40px;
            gap: 32px;
            background: $gray_first_color;
            border-radius: 12px;

            &-header {
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: space-between;

                &-title {
                    @include desktop_subTitle_L;
                    letter-spacing: -0.01em;
                    font-feature-settings: 'cpsp' on;
                    color: $display_color;
                }

                &-redo {
                    @include desktop_body_S12;
                    color: rgba(24, 35, 37, 0.72);
                    cursor: pointer;
                }
            }


            &-content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 16px;
                width: 100%;

                &-item {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 8px;
                    width: 100%;

                    &-text {
                        @include desktop_body_s;
                        color: $body_color;
                    }

                    &-group {
                        display: flex;
                        flex-direction: row;
                        gap: 15px;
                        width: 100%;
                    }
                }
            }

            &-button {
                width: 100%;
            }
        }
    }

    &-disable-background {
        display: block;
        width: 100vw;
        height: 100vh;
        position: fixed;
        z-index: 999;
        left: 0px;
        top: 0px;
        background: rgba(0, 0, 0, 0.8);

        &-img {
            position: absolute;
            display: none;
            height: -webkit-fill-available;
            width: -webkit-fill-available;
        }
    }

    &-dialog {
        display: block;
        position: fixed;
        z-index: 1000;
        width: 46vw;
        height: fit-content;
        overflow-y: auto;
        background: $bg_color;
        right: 27vw;
        top: -150vh;
        transition: all 0.4s;
        border-radius: 24px;

        .close-btn {
            position: absolute;
            right: 16px;
            top: 16px;
        }

        &-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 4.87vw 8.26vw;
            gap: 3.9vh;

            .howtomake {
                padding: 0px;

                &-header {
                    display: none;
                }

                &-content-item {
                    background: none;
                    flex-direction: column;
                    align-items: center;

                    &-img {
                        width: 100%;
                    }

                    &-content {
                        gap: 24px;
                        align-items: center;
                        padding: 0px;
                        padding-top: 1.77vw;

                        &-up {

                            &-title {
                                text-align: center;
                                color: $display_color;
                            }

                            &-desc {
                                color: rgba($display_color, 0.4);
                            }
                        }

                        &-control {
                            position: initial;
                            margin-left: -72px;
                        }
                    }
                }
            }
        }
    }

    .activedlg {
        top: 10vh;
    }
}

@media screen and (max-width: 825px) {
    .edit {
        &-down {
            &-left {
                &-button-group {
                    .button {
                        @include mobile_body_S12;
                    }
                }
            }

            &-right {
                padding: 20px;
                gap: 16px;

                &-header {
                    &-title {
                        @include mobile_heading_h3-20;
                    }
                }

                &-content {
                    gap: 16px;

                    &-item {
                        &-text {
                            @include mobile_body_S12;
                        }
                    }
                }

                &-button {
                    @include mobile_body_S12;
                }
            }
        }

        &-dialog {
            width: 90vw;
            right: 5vw;

            .close-btn {
                right: 14px;
                top: 14px;
            }

            &-content {
                padding-top: 8vw;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .edit {
        margin-top: 56px;
        gap: 16px;

        &-down {
            flex-direction: column;
            align-items: center;
            gap: 6.4vw;

            &-left {
                width: 100%;
            }

            &-right {
                width: 100%;
            }
        }

        &-dialog {
            border-radius: 12px;

            .close-btn {
                right: 12px;
                top: 12px;
            }

            &-content {
                padding-top: 12vw;
            }
        }
    }
}