@import '../../global/style.scss';

.hero {
    display: flex;
    flex-direction: column;

    // .showHelpIcon {
    //     margin-top: 0px;
    //     display: flex;
    //     flex-direction: row;
    //     align-items: center;
    //     //margin-right: 8px;
    //     gap: 10px;
    //     @include desktop_body_s_bold;
    //     //padding: 10px 10px 10px 10px;
    // }

    // .showHelpIcon:hover+&-helpIcon,
    // .showToolTip,
    // .showHelpIcon:hover &-helpIcon {
    //     opacity: 1;
    // }

    // &-helpIcon:hover {
    //     opacity: 1;
    //     background-color: rgba(211, 211, 211, 0.527);
    // }

    // &-helpIcon:hover &-helpIcon-toolTip,
    // .showToolTip &-helpIcon-toolTip {
    //     display: block;
    // }

    // &-helpIcon {
    //     opacity: 0.0;
    //     //color: gray;
    //     display: flex;
    //     width: 25px;
    //     height: 25px;
    //     border-style: solid;
    //     border-color: gray;
    //     border-width: 1px;
    //     border-radius: 15px;
    //     justify-content: center;
    //     margin-left: 6px;
    //     margin-right: 10px;

    //     &-toolTip {
    //         // :active {
    //         //     display: none;
    //         // }

    //         display: none;
    //         opacity: 1;
    //         width: 300px;

    //         position: absolute;
    //         //justify-content: center;
    //         //left: 10%;
    //         //top: 20px;
    //         z-index: 1010;

    //         &-text {
    //             color: black;
    //             border: 2px solid black;
    //             background-color: white;
    //             margin-top: 30px;
    //             text-align: left;
    //             padding: 9px;
    //             border-radius: 6px;
    //             box-shadow: #00000014;
    //             z-index: 1020
    //         }
    //     }

    //     // &-container:hover {
    //     //     display: block;
    //     // }
    // }

    &-dragContainer {
        padding: 40px 40px 20px 0px;
    }

    &-main {
        display: flex;
        //padding: 3% 8.3% 7.2% 8.3%;
        padding-top: 3%;
        padding-bottom: 7%;
        flex-direction: row;

        &-up {
            display: flex;
            flex-direction: column;
            justify-content: center;
            //gap: 40px;
            align-items: center;

            &-title {
                @include desktop_heading_h1-h2;
                //font-size: 4vw;
                letter-spacing: -0.01em;
                font-feature-settings: 'pnum' on, 'lnum' on, 'kern' off;

                color: $display_color;
                text-align: center;
                padding: 0px 40px 0px 0px;
            }

            &-prices {
                height: 71px;
                width: 100%;
                //padding: 0px 40px 0px 0px;

                &-holder {
                    justify-content: center;
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    &-title {
                        @include desktop_subTitle_S18;
                        text-align: center;
                    }

                    &-types {
                        justify-content: center;
                        display: flex;
                        flex-direction: row;

                    //     .oldPrice {
                    //         text-decoration: line-through;
                    //     }

                    //     &-background {
                    //         display: none;
                    //         z-index: 1000;
                    //         background-color: gray;
                    //         opacity: 80%;
                    //         top: 0px;
                    //         left: 0px;
                    //         width: 100vw;
                    //         height: 100vh;
                    //         overflow: hidden;
                    //     }

                    //     .showBackGround {
                    //         display: block;
                    //         position: fixed;
                    //     }

                    //     &-container {
                    //         display: flex;
                    //         flex-direction: row;
                    //         align-items: center;
                    //         cursor: pointer;

                    //         &-digital {
                    //             background: #F1F0FA;
                    //             border-radius: 75px;
                    //             padding: 10px 10px 10px 10px;
                    //         }

                    //         &-printed {
                    //             background: linear-gradient(270deg, rgba(255, 237.45, 192.31, 0.25) 0%, rgba(255, 192.82, 32.94, 0.07) 100%);
                    //             border-radius: 75px;
                    //             padding: 10px 10px 10px 10px;
                    //         }

                    //     }
                     }
                }
            }
        }

        &-down {
            //max-height: 350px;
            min-width: 150px;

            img {
                min-width: 150px;
                width: 100%;
            }
        }
    }

    &-compare {
        //padding: 2vw 8.27vw 5vw 8.27vw;   
        padding-top: 2vw;
        padding-bottom: 5vw;
        display: flex;
        flex-direction: row;

        &-tiles {
            display: flex;
            flex-direction: row;
            width: 100%;

            &-tile {
                width: 33%;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: -1;

                .first {
                    //background-color: $main_color;
                    background-color: #155d6b;
                    color: white;
                }

                .last {
                    background-color: $tone_first_color;
                }

                &-container {
                    box-shadow: 4px 4px 22px 0px #B2B2B2;
                    min-width: 100px;
                    max-width: 500px;
                    display: flex;
                    flex-direction: row;
                    background-color: $secondary_color;
                    border-radius: 5px;
                    padding: 10px;
                    width: 90%;

                    &-text {
                        width: 70%;
                        @include desktop_body_ss;

                        img {
                            width: 20px;
                            padding-left: 5px;
                            align-items: center;
                        }

                        &-point {
                            display: flex;
                            align-items: center;
                        }

                        &-name {
                            @include desktop_body_s_bold;
                            display: flex;
                            flex-direction: row;
                        }

                        &-price {
                            @include desktop_body_l;
                            font-weight: 600;
                        }
                    }

                    .last {
                        filter: blur(1px);
                    }

                    &-img {
                        width: 30%;

                        img {
                            float: right;
                            width: 95%;
                            border-radius: 5px;
                        }
                    }
                }
            }
        }

    }

    // &-social {
    //     display: none;

    //     &-title {
    //         text-align: center;
    //         font-family: "Poppins";
    //         font-weight: 600;
    //     }

    //     &-numbers {
    //         padding: 2vw 8.27vw 5vw 8.27vw;

    //         &-holder {
    //             height: 119px;
    //             display: flex;
    //             flex-direction: row;
    //             background: linear-gradient(270deg, rgba(255, 202.94, 227.93, 0.20) 0%, rgba(209.11, 194.11, 251.81, 0.17) 20%, rgba(154.06, 200.49, 255, 0.14) 38%, rgba(187.89, 245.93, 218.07, 0.07) 53%, rgba(53.12, 255, 158.10, 0.04) 72%, rgba(180.60, 232.68, 139.67, 0.02) 84%, rgba(255, 202.94, 227.93, 0) 100%);
    //             padding: 2vw 0 2vw 0;
    //             border: 2px solid white;
    //             border-radius: 30px;
    //             box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.07);

    //             &-item {
    //                 display: flex;
    //                 flex-direction: column;
    //                 width: 33%;
    //                 text-align: center;
    //                 font-family: "Poppins";
    //                 font-weight: 500;

    //                 &-number {
    //                     font-size: 32px;
    //                     font-weight: 600;
    //                 }
    //             }
    //         }
    //     }
    // }
}

.hideFooter {
    display: none;
}


@media screen and (max-width: 970px) {
    .hero {
        &-main {
            //padding: 3.1vw 8.27vw 2vw 8.27vw;
            padding-top: 3.1vw;
            padding-bottom: 2vw;

            flex-direction: row;

            &-up {

                &-title {
                    font-size: 4.3vw;
                    padding: 0px 40px 0px 0px;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .hero {
        .left {
            left: 30px;
        }

        .digital {
            right: 30px;
        }

        &-main {
            //padding: 3.1vw 10.4vw 3.3vw 10.4vw;
            padding-top: 3.1vw;
            padding-bottom: 2vw;
            flex-direction: column;

            &-dragContainer {
                padding: 40px 0px 20px 0px;
            }

            &-up {
                &-prices {
                    width: 100%;
                    padding: 0px 0px 0px 0px;

                    // &-types-container-help-container {
                    //         right: 30px;
                    //     }
                }

                &-title {
                    font-size: 4.3vw;
                    padding: 0px 0px 0px 0px;
                }
            }

            &-down {
                padding: 5vw 15vw 0vw 15vw;
            }
        }

        &-compare {
            //padding: 2vw 8.27vw 5vw 8.27vw;
            padding-top: 2vw;
            padding-bottom: 5vw;

            display: flex;
            flex-direction: row;

            &-tiles {
                display: flex;
                flex-direction: column;
                width: 100%;

                &-tile {
                    width: 100%;
                    padding-bottom: 12px;

                    &-container {
                        width: 100%;
                    }
                }
            }
        }

        &-social-numbers-holder-item {
            font-size: 20px;

            &-number {
                font-size: 26px;
                font-weight: 600;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .hero {
        .left {
            left: 30px;
        }

        .digital {
            right: 30px;
        }

        &-main {
            //padding: 3% 5% 3% 5%;
            padding-top: 3.1vw;
            padding-bottom: 2vw;
            flex-direction: column;

            &-dragContainer {
                padding: 15px 0px 5px 0px;
            }

            &-up {
                &-prices {
                    padding: 0px 0px 0px 0px;

                    &-holder {

                        &-types {
                            align-items: center;

                            // div {
                            //     margin-bottom: 4px;
                            //     //width: fit-content;

                            //     span {
                            //         display: none;
                            //     }
                            // }
                        }
                    }
                }

                &-title {
                    font-size: 7vw;
                }
            }

            &-down {
                padding: 3vw 15vw 0vw 15vw;
                min-height: 220px;
            }
        }

        &-compare {
            //padding: 2vw 8.27vw 5vw 8.27vw;
            padding-top: 2vw;
            padding-bottom: 5vw;

            display: flex;
            flex-direction: row;

            &-tiles {
                display: flex;
                flex-direction: column;
                width: 100%;

                &-tile {
                    width: 100%;
                    padding-bottom: 12px;

                    &-container {
                        width: 100%;
                    }
                }
            }
        }

        // &-social {
        //     &-numbers {
        //         padding: 2vw 8.27vw 13vw 8.27vw;

        //         &-holder-item {
        //             font-size: 14px;

        //             &-number {
        //                 font-size: 23px;
        //                 font-weight: 600;
        //             }
        //         }
        //     }
        // }
    }
}