@import '../../../global/style.scss';

.dropdown {
    position: relative;
    width: 100%;

    &-body {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 16px;
        gap: 10px;
        border-radius: 8px;
        white-space: nowrap;
        min-width: max-content;
        cursor: pointer;
        background-color: $bg_color;

        &-text {
            @include desktop_body_m;
            display: flex;
            align-items: center;
            letter-spacing: -0.408px;
            color: $body_color;
        }

        &-icon {
            margin-right: 17px;

            img {
                width: 100%;
            }
        }

        &-vector {
            img {
                width: 100%;
            }
        }
    }

    &-menu {
        position: absolute;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        overflow-y: scroll;
        max-height: 300px;
        margin: 3px 0px 0px 0px;
        border-bottom: 2px solid $gray_first_color;
        gap: 2px;
        background-color: $gray_first_color;
        border-radius: 12px;

        .input {
            padding: 14px 20px;
            border-radius: 12px 12px 0px 0px;

            input {
                @include desktop_body_s;
                letter-spacing: -0.408px;
            }
        }

        &-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 14px 20px;
            gap: 17px;
            width: -webkit-fill-available;

            background: #FFFFFF;
            // border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
            border-radius: 0;

            &:first-child {
                border-radius: 12px 12px 0px 0px;
            }

            &:last-child {
                border-radius: 0px 0px 12px 12px;
            }

            &-text {
                @include desktop_body_s;
                letter-spacing: -0.408px;
                color: $display_color;

                span {
                    margin-top: -10px;
                    @include desktop_body_S13;
                    color: #64748B;
                }
            }
        }

    }

}

.z-index-1 {
    z-index: 1;
}

@media screen and (max-width: 768px) {
    .dropdown {
        &-body {
            padding: 11px 16px;

            &-text {
                @include mobile_body_S12_light;
            }
        }

        &-menu {
            .input {
                padding: 11px 20px;

                input {
                    @include mobile_body_S11;
                }

                img {
                    width: 18px;
                }
            }

            &-item {
                padding: 11px 20px;

                &-text {
                    @include mobile_body_S11;

                    span {
                        @include mobile_body_S10;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:426px) {
    .dropdown {}
}