@import '../../../global/style.scss';

.AdminDashboardRow {
    display: flex;
    flex-direction: row;

    &-month {
        width: 100px;
    }

    &-totalSessionCount {
        width: 150px;
    }

    &-successSessions {
        width: 150px;
    }

    &-errorCount {
        width: 150px;
    }
    
    &-totalOrders {
        width: 150px;
    }
    
    &-totalPaidOrders {
        width: 150px;
    }

    &-totalPaid {
        width: 150px;
    }
}