@import '../../global/style.scss';

.priceFront {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    &-background {
        display: none;
        z-index: 1000;
        background-color: gray;
        opacity: 80%;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
    }

    .showBackGround {
        display: block;
        position: fixed;
    }

    &-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        .showHelpIcon {
            margin-top: 0px;
            display: flex;
            flex-direction: row;
            align-items: center;
            //margin-right: 8px;
            gap: 10px;
            @include desktop_body_s_bold;
            //padding: 10px 10px 10px 10px;
        }

        .showHelpIcon:hover+&-helpIcon,
        .showToolTip,
        .showHelpIcon:hover &-helpIcon {
            opacity: 1;
        }

        &-helpIcon:hover {
            opacity: 1;
            background-color: rgba(211, 211, 211, 0.527);
        }

        &-helpIcon:hover &-helpIcon-toolTip,
        .showToolTip &-helpIcon-toolTip {
            display: block;
        }

        &-helpIcon {
            opacity: 0.0;
            //color: gray;
            display: flex;
            width: 25px;
            height: 25px;
            border-style: solid;
            border-color: gray;
            border-width: 1px;
            border-radius: 15px;
            justify-content: center;
            margin-left: 6px;
            margin-right: 10px;

            &-toolTip {
                // :active {
                //     display: none;
                // }

                display: none;
                opacity: 1;
                width: 300px;

                position: absolute;
                //justify-content: center;
                //left: 10%;
                //top: 20px;
                z-index: 1010;

                &-text {
                    color: black;
                    border: 2px solid black;
                    background-color: white;
                    margin-top: 30px;
                    text-align: left;
                    padding: 9px;
                    border-radius: 6px;
                    box-shadow: #00000014;
                    z-index: 1020
                }
            }

            // &-container:hover {
            //     display: block;
            // }
        }

        &-type {
            border-radius: 75px;
            padding: 10px 10px 10px 10px;

            .oldPrice {
                text-decoration: line-through;
            }

            &-newPrice {
                color: red;
            }

            &-values {
                display: flex;
                flex-direction: row;
                

                &-regular {
                    margin-right: 5px;
                    
                }
            }
        }

        .digitalS {
            background: #F1F0FA;
        }

        .printedS {
            background: linear-gradient(270deg, rgba(255, 237.45, 192.31, 0.25) 0%, rgba(255, 192.82, 32.94, 0.07) 100%);
        }
    }
}

@media screen and (max-width: 768px) {
    .priceFront {
        &-container {
            &-helpIcon {
                width: 0px;
                border-style: none;
                border-color: white;
    
                //opacity: 0;
                &-question {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .priceFront {
        &-container {
            &-type-dscrpt {
                display: none;
            }

            &-helpIcon-toolTip {
                left: 7%;
                width: 86%;
            }
        }
    }
}