@import '../../global/style.scss';

.step-content3 {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
    gap: 1.38vw;
    padding: 3% 2%;
    margin-bottom: 8.33vw;

    &-left {
        flex: 29.8%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 2.7vw;
        gap: 1.38vw;

        background: $gray_first_color;
        border-radius: 0.83vw;

        &-title {
            @include desktop_subTitle_L;
            color: $display_color;
        }

        &-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.83vw;

            &-item {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                &-title {
                    @include desktop_body_s;
                    color: $body_color;
                }

                &-content {
                    @include desktop_body_s_bold;
                    color: $display_color;
                }
            }
        }
    }

    &-mid {
        flex: 40.3%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 0.83vw;
        gap: 1.38vw;

        &-img {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border: 2px solid #F5F5F5;
            border-radius: 0.83vw;
            position: relative;
            width: 100%;

            &-photo {
                width: 100%;
                border-radius: 0.83vw;
            }

            &-more {
                align-self: flex-end;
                position: absolute;
                margin: 0.55vw;
                width: 56px;
                height: 56px;
            }
        }

        &-button-group {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            align-items: flex-start;
            gap: 1.38vw;
            white-space: nowrap;

            .button {
                flex: 50%;
            }
        }
    }

    &-right {
        flex: 29.8%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 40px;
        gap: 32px;
        background: $gray_first_color;
        border-radius: 12px;

        &-title {
            @include desktop_subTitle_L;
            letter-spacing: -0.01em;
            font-feature-settings: 'cpsp' on;
            color: $display_color;
        }

        &-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            width: 100%;

            &-item {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                width: 100%;

                &-text {
                    @include desktop_body_s;
                    color: $body_color;
                }
            }
        }

        &-button {
            width: 100%;
        }
    }
}

@media screen and (max-width: 768px) {
    .step-content3 {
        &-left {
            &-title {
                @include mobile_heading_h3-20;
            }

            &-content {
                &-item {
                    &-title {
                        @include mobile_body_S12;
                    }

                    &-content {
                        @include mobile_subTitle_S14_bold;
                    }
                }
            }
        }

        &-mid {
            &-button-group {
                .button {
                    @include mobile_body_S12;
                }
            }
        }

        &-right {
            padding: 20px;
            gap: 16px;

            &-title {
                @include mobile_heading_h3-20;
            }

            &-content {
                gap: 16px;

                &-item {
                    &-text {
                        @include mobile_body_S12;
                    }
                }
            }

            &-button {
                @include mobile_body_S12;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .step-content3 {
        flex-direction: column;
        gap: 16px;
    }
}