@import '../../../global/style.scss';

.reference {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 1.38vw;
    background: $gray_first_color;
    border-radius: 24px;
    padding: 2.77vw;

    &-show {
        flex: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 9.02vw 0vw;

        &-img {
            width: 23.6vw;
        }
    }

    &-body {
        flex: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;

        &-img {
            width: 3.88vw;
        }

        &-title {
            @include desktop_heading_h2;
            letter-spacing: -0.01em;
            font-feature-settings: 'cpsp' on;
            color: $display_color;
        }

        &-text {
            @include desktop_body_l;
            color: $body_color;
        }
    }
}

@media screen and (max-width: 870px) {
    .reference {
        border-radius: 12px;

        &-body {
            gap: 6px;

            &-img {
                width: 25px;
            }

            &-title {
                @include mobile_heading_h2-24;
                letter-spacing: -0.01em;
                font-feature-settings: 'cpsp' on;
                color: $display_color;
            }

            &-text {
                @include mobile_body_S12;
                color: $body_color;
            }
        }
    }
}

@media screen and (max-width:426px) {
    .reference {
        flex-direction: column;

        &-show {
            flex: 100%;

            &-img {
                width: 55.3vw;
            }
        }

        &-body {
            flex: 100%;
        }
    }

    .RTL {
        flex-direction: column-reverse;
    }
}