@import '../../global/style.scss';

.reviews {
    display: flex;
    flex-direction: column;
    gap: 2.22vw;
    background-color: $display_color;
    padding: 4% 8.3% 7.2% 8.3%;

    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-title {
            @include desktop_heading_h2;

            letter-spacing: -0.01em;
            font-feature-settings: 'cpsp' on;

            color: $bg_color;

            span {
                display: inline-block;
                color: $minor_color;
                @include desktop_heading_h3;
                margin-left: 24px;
            }
        }

        &-control {
            display: flex;
            gap: 4%;
        }
    }

    &-content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2%;

        &-item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 2.77vw;
            gap: 1.66vw;
            background: rgba(245, 245, 245, 0.1);
            border-radius: 12px;
            color: $bg_color;

            &-header {
                @include desktop_subTitle_L;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 1.11vw;

                img {
                    width: 56px;
                    height: 56px;
                    border-radius: 100%;
                }

                .name-rating {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    .name {
                        @include desktop_body_l;
                    }

                    .rating {
                        @include desktop_body_s;
                        flex-direction: row;
                        align-items: center;
                        gap: 2px;

                        span {
                            color: $minor_color;

                            img {
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }
                }
            }

            &-desc {
                @include desktop_body_s;
                opacity: 0.5;
            }
        }
    }

    &-footer {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .reviews {
        padding: 2% 3.5% 3.5% 3.5%;

        &-header {
            &-title {
                @include mobile_heading_h2-24;

                letter-spacing: -0.01em;
                font-feature-settings: 'cpsp' on;

                color: $bg_color;

                span {
                    img {
                        width: 21px;
                        height: 21px;
                    }
                }
            }

            &-control {
                img {
                    width: 36px;
                    height: 36px;
                }
            }
        }

        &-content {
            grid-template-columns: repeat(2, 1fr);
            margin-top: 20px;

            &-item {
                border-radius: 12px;
                padding: 40px 20px;

                &:last-child {
                    display: none;
                }

                &-header {
                    @include mobile_heading_h3-20;

                    display: flex;
                    align-items: center;
                    gap: 16px;

                    .name-rating {
                        .name {
                            @include mobile_heading_h3-20;
                        }

                        .rating {
                            @include mobile_subTitle_S15;
                            display: flex;
                            align-items: center;
                            gap: 6px;

                            span {
                                color: $minor_color;

                                img {
                                    width: 16px;
                                    height: 16px;
                                }
                            }
                        }
                    }
                }

                &-desc {
                    @include mobile_subTitle_S14;
                    color: $bg_color;
                    opacity: 0.5;
                }
            }
        }

        &-footer {
            display: none;
        }
    }
}

@media screen and (max-width: 500px) {
    .reviews {
        padding: 5% 5% 5% 5%;
        margin-top: 40px;

        &-header {
            &-title {
                @include mobile_heading_h2-24;

                letter-spacing: -0.01em;
                font-feature-settings: 'cpsp' on;

                color: $bg_color;

                span {
                    color: $minor_color;
                    @include mobile_subTitle_S18;
                    display: inline-block;
                    margin-left: 24px;

                    img {
                        width: 21px;
                        height: 21px;
                    }
                }
            }

            &-control {
                display: none;
                gap: 4%;

                img {
                    width: 36px;
                    height: 36px;
                }
            }
        }

        &-content {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 2%;
            margin-top: 20px;

            &-item {
                border-radius: 12px;
                padding: 43px 40px;
                animation-name: slide;
                animation-duration: 1s;

                &:not(:first-child) {
                    display: none;
                }

                &-header {
                    @include mobile_heading_h3-20;

                    display: flex;
                    align-items: center;
                    gap: 16px;

                    img {
                        width: 56px;
                        height: 56px;
                    }

                    .name-rating {
                        display: flex;
                        flex-direction: column;

                        .name {
                            @include mobile_heading_h3-20;
                        }

                        .rating {
                            @include mobile_subTitle_S15;
                            display: flex;
                            align-items: center;
                            gap: 6px;

                            span {
                                color: $minor_color;

                                img {
                                    width: 16px;
                                    height: 16px;
                                }
                            }
                        }
                    }
                }

                &-desc {
                    @include mobile_subTitle_S14;
                    color: $bg_color;
                    opacity: 0.5;
                }
            }

            @keyframes slide {
                0% {
                    opacity: 0%;
                }

                100% {
                    opacity: 100%;
                }
            }
        }

        &-footer {
            display: flex;
            justify-content: center;
            margin-top: 20px;
            gap: 10px;
        }
    }
}