@import '../../global/style.scss';

.footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2.77%;
    gap: 2.77vw;
    //margin-top: 8.33vw;

    background: $display_color;

    &-up {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 5.5%;
        width: 100%;
        justify-content: space-between;

        &-pages {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 1.38vw;
            white-space: nowrap;

            a {
                @include desktop_body_s_bold;
                color: $bg_color;
                cursor: pointer;
            }

            .selected {
                color: $gray_fourth_color;
            }
        }

        &-contacts {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 2.2vw;

            &-social {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 0.5vw;

                &-title {
                    @include desktop_body_s;
                    color: $bg_color;
                    opacity: 0.6;
                }

                &-icon-group {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    gap: 0.5%;
                }
            }

            &-get-in-touch {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 0.5vw;

                &-title {
                    @include desktop_body_s;
                    color: $bg_color;
                    opacity: 0.6;
                }

                &-contact-group {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    &-item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 0.5%;
                        white-space: nowrap;
                        cursor: pointer;

                        &-text {
                            @include desktop_body_s;
                            color: $bg_color;
                        }
                    }
                }
            }
        }
    }

    &-down {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 2.6%;
        width: 100%;
        white-space: nowrap;

        &-policy-group {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10%;

            a {
                @include desktop_body_s_bold;
                color: $bg_color;
                cursor: pointer;
                height: 48px;
            }

            .selected {
                color: $gray_fourth_color;
            }
        }

        &-passport {
            @include desktop_body_ss;
            text-align: right;
            color: $bg_color;
            opacity: 0.6;
        }
    }

    &-disable-background {
        display: block;
        width: 100vw;
        height: 100vh;
        background-color: gray;
        position: fixed;
        z-index: 999;
        left: 0px;
        top: 0px;
        background: rgba($display_color, 0.4);

        &-img {
            position: absolute;
            display: none;
            height: -webkit-fill-available;
            width: -webkit-fill-available;
        }
    }

    .EmailContent {
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 4.87vh 2.77vw;
        gap: 3.9vh;
        isolation: isolate;

        &-title {
            @include desktop_heading_h3;
            letter-spacing: -0.01em;
            font-feature-settings: 'cpsp' on;
            color: $display_color;
        }

        &-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 2.43vh;
            width: 100%;

            &-item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 0.48vh;
                width: 100%;

                &-label {
                    @include desktop_body_s;
                }

                .input {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    gap: 4%;
                    border-radius: 8px;
                    padding: 1.38vw 16px;
                    justify-content: space-between;

                    input {
                        border: 0px;
                        outline: 0px;
                        width: 100%;

                        @include desktop_body_m;
                        // font-size: 1.25vw;
                    }
                }

                &-textarea {
                    background-color: $gray_first_color;
                    min-height: 130px;
                    border-radius: 8px;
                    align-self: stretch;
                    border: none;
                    padding: 12px;
                    @include desktop_body_m;
                }
            }
        }

        &-button {
            width: 100%;

            input {
                display: none;
            }
        }
    }

    .active {
        top: 10vh;
    }
}

@media screen and (max-width: 690px) {
    .footer {
        &-up {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 1.1vw;
        }

        &-down {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.6vw;

            &-policy-group {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 0.8vw;
            }
        }

        .EmailContent {
            &-title {
                @include mobile_heading_h2-24;
            }

            &-content {
                &-item {
                    &-label {
                        @include mobile_subTitle_S14;
                    }

                    .input {
                        input {
                            @include mobile_subTitle_S14;
                        }
                    }

                    &-textarea {
                        @include mobile_subTitle_S14;
                    }
                }
            }

            &-button {
                @include mobile_subTitle_S14_bold_b;
            }
        }
    }

    .active {
        min-width: 80%;
    }
}