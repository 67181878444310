@import '../../../global/style.scss';

.popup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    width: 100%;

    &-body {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        padding: 16px 20px;
        gap: 10px;

        background: $gray_first_color;
        border-radius: 8px;

        &-text {
            @include desktop_body_l;
            color: $display_color;
        }

        &-img {
            width: 100%;
        }
    }

    &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 20px;
        gap: 20px;
        width: 100%;
        height: fit-content;
        // animation-name: analyze;
        // animation-duration: 0.1s;

        background: $gray_first_color;
        border-radius: 8px;

        &-text {
            @include desktop_body_m;
            color: $body_color;
        }
    }

    .hide {
        display: none;
    }

    @keyframes analyze {
        100% {
            height: fit-content;
        }
    }
}

@media screen and (max-width: 870px) {
    .popup {
        &-body {
            &-text {
                @include mobile_subTitle_S15;
                display: flex;
                align-items: center;
                letter-spacing: -0.01em;
                font-feature-settings: 'cpsp' on;
                color: $display_color;
            }
        }

        &-content {
            padding: 20px 16px;

            &-text {
                @include mobile_subTitle_S14;
                color: $body_color;
            }
        }
    }
}

@media screen and (max-width:426px) {
    .popup {}
}