@import '../../../global/style.scss';

.step-letter {
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 1.38vw;
    padding: 0% 2%;

    &-item {
        flex: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;

        &-title {
            @include desktop_body_m;
            text-align: center;
            color: $display_color;
        }

        &-content {
            @include desktop_body_s;
            text-align: center;
            opacity: 0.6;
        }
    }
}

@media screen and (max-width: 768px) {
    .step-letter {
        &-item {
            &-title {
                @include mobile_body_S16;
            }

            &-content {
                @include mobile_subTitle_S14;
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .step-letter {
        &-item {
            &-title {
                @include mobile_subTitle_S12;
            }

            &-content {
                @include mobile_body_S8;
            }
        }
    }
}