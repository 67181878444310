@import '../../global/style.scss';

.order {
    display: flex;
    flex-direction: row;
    margin-top: 75px;
    gap: 2.4vw;
    padding: 3.12vw 6.8vw;

    &-alert {
        display: block;
        position: fixed;
        z-index: 1000;
        width: 46vw;
        height: fit-content;
        background: $bg_color;
        color: $error_color;
        right: 27vw;
        top: -150vh;
        transition: all 0.4s;
        border-radius: 10px;
        padding: 20px;
        text-align: center;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .active {
        top: 20vh;
    }

    &-left {
        flex: 45%;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 1vw;

        .price-panel {
            width: 100%;
        }

        &-down {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1vw;
            justify-content: space-between;
            align-self: stretch;

            &-img {
                flex: 50%;
                width: 15.97vw;
            }

            &-photos {
                flex: 50%;
                box-sizing: border-box;
                border: 2px solid $main_color;
                border-radius: 12px;

                display: flex;
                flex-direction: row;
                align-items: center;

                &-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    img {
                        border-radius: 12px;
                        width: 9.11vw;
                    }
                }

            }
        }

        &-addImgs {
            align-self: flex-start;
            position: relative;

            &-passport {
                position: absolute;
                display: block;
                width: 10.62vw;
                right: -5.77vw;
                top: -6vw;
            }

            &-arrow {
                position: absolute;
                display: block;
                left: 12vw;
                top: 0vw;
                width: 13vw;
            }
        }
    }

    &-right {
        flex: 55%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        width: 100%;

        &-title {
            @include desktop_heading_h2;
        }

        &-up {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 2.77vw;
            gap: 20px;
            align-self: stretch;

            background: $gray_first_color;
            border-radius: 12px;

            &-title {
                @include desktop_subTitle_L;

                letter-spacing: -0.01em;
                font-feature-settings: 'cpsp' on;

                color: $display_color;
            }

            &-up {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 4px;
                align-self: stretch;

                &-label {
                    @include desktop_body_s;
                    color: $body_color;
                }

                .input {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    gap: 4%;
                    border-radius: 8px;
                    padding: 1.38vw 16px;
                    justify-content: space-between;

                    input {
                        border: 0px;
                        outline: 0px;
                        width: 100%;

                        @include desktop_body_m;
                        // font-size: 1.25vw;
                    }
                }
            }

            &-down {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 4px;
                align-self: stretch;

                &-label {
                    @include desktop_body_s;
                    color: $body_color;
                }

                &-textarea {
                    background-color: $bg_color;
                    min-height: 130px;
                    border-radius: 8px;
                    align-self: stretch;
                    border: none;
                    padding: 12px;
                    @include desktop_body_m;
                }
            }
        }

        &-down {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 2.77vw;
            gap: 20px;

            background: $gray_first_color;
            border-radius: 12px;
            align-self: stretch;

            &-title {
                @include desktop_subTitle_L;
            }

            &-up {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                align-self: stretch;
            }

            &-down {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                align-self: stretch;

                &-card {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    padding: 1.66vw;
                    gap: 20px;
                    align-self: stretch;

                    background: $bg_color;
                    border-radius: 8px;

                    &-up {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        gap: 4px;
                        align-self: stretch;

                        &-label {
                            @include desktop_body_s;
                            color: $body_color;
                        }

                        div {
                            img {
                                height: 100%;
                            }
                        }
                    }

                    &-down {
                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;
                        gap: 1.38vw;

                        &-item {
                            flex: 50%;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: flex-start;
                            gap: 4px;

                            .input {
                                width: 100%;
                                display: flex;
                                align-items: center;
                                gap: 4%;
                                border-radius: 8px;
                                padding: 1.38vw 16px;
                                justify-content: space-between;

                                input {
                                    border: 0px;
                                    outline: 0px;
                                    width: 100%;

                                    @include desktop_body_m;
                                    // font-size: 1.25vw;
                                }
                            }

                            &-label {
                                @include desktop_body_s;
                                color: $body_color;
                            }

                            &-group {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                gap: 4px;
                            }
                        }
                    }

                    &-button {
                        align-self: stretch;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .order {
        &-right {
            &-title {
                @include mobile_heading_h2-24;
            }

            &-up {
                &-title {
                    @include mobile_heading_h3-20;
                }

                &-up {
                    &-label {
                        @include mobile_body_S12;
                    }

                    input {
                        @include mobile_body_S12;
                    }

                    .checkbox-text {
                        @include mobile_body_S12;
                    }
                }

                &-down {
                    &-label {
                        @include mobile_body_S12;
                    }

                    &-textarea {
                        @include mobile_body_S12;
                    }
                }
            }

            &-down {
                &-title {
                    @include mobile_heading_h3-20;
                }

                &-up {
                    .payment {
                        @include mobile_subTitle_S18;
                    }
                }

                &-down {
                    &-card {
                        &-up {
                            &-label {
                                @include mobile_body_S12;
                            }

                            input {
                                @include mobile_subTitle_S14;
                            }
                        }

                        &-down {
                            &-item {
                                &-label {
                                    @include mobile_body_S12;
                                }

                                input {
                                    @include mobile_subTitle_S14;

                                }
                            }
                        }

                        &-button {
                            @include mobile_body_S12;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .order {
        flex-direction: column;
        gap: 10vw;

        &-left {
            &-down {
                &-photos {
                    &-item {
                        img {
                            width: 21vw;
                        }
                    }

                }
            }

            &-addImgs {
                &-passport {
                    width: 20.62vw;
                    right: -13.31vw;
                    top: -13vw;
                }

                &-arrow {
                    left: 30vw;
                    width: 27vw;
                }
            }
        }
    }
}