@import '../../../global/style.scss';

.input {
    display: flex;
    align-items: center;
    gap: 4%;

    justify-content: space-between;
    width: 100%;

    background-color: $bg_color;
    border-radius: 8px;
    padding: 1.38vw 16px;

    .hand {
        cursor: pointer;
    }

    input {
        border: 0px;
        outline: 0px;
        background-color: $bg_color;
        width: 100%;

        @include desktop_body_m;
    }
}

.gray {
    background-color: $gray_first_color;

    input {
        background-color: $gray_first_color;
    }
}

.success-input {
    background-color: $success100_color;

    input {
        background-color: $success100_color;
    }
}

.error-input {
    background-color: $error100_color;

    input {
        background-color: $error100_color;
    }
}

.message {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;

    &-text {
        @include mobile_body_S12_light;
    }
}

.success-message {
    color: $success_color;
}

.error-message {
    color: $error_color;
}