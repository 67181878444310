
.FullWidth {
    margin-top: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &-container {
        padding: 0px 2% 2% 2%;
        width: 100%;
        max-width: 1200px;

    }
}

@media screen and (max-width: 870px) {
    .FullWidth {
        &-container {
            padding: 0px 3.5% 3.5% 3.5%;
        }
    }
}

@media screen and (max-width: 500px) {
    .FullWidth {
        margin-top: 45px;

        &-container {
            padding: 0px 5% 5% 5%;
        }
    }
}
