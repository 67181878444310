@import '../../../global/style.scss';

.blog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.3vw;
    margin-bottom: 20px;

    &-img {
        img {
            width: 100%;
            border-radius: 12px;
        }
    }

    &-body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.83vw;

        &-btn-title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 1.66vw;

            &-btn-date {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 3%;
                white-space: nowrap;
                @include desktop_body_s;

                &-date {
                    text-align: center;
                    color: $body_color;
                    font-size: 1.4vw;
                }
            }

            &-title {
                @include desktop_subTitle_L;
                letter-spacing: -0.01em;
                font-feature-settings: 'cpsp' on;
                color: $body_color;
                //font-size: 2.22vw;
            }
        }

        &-content {
            @include desktop_body_m;
            opacity: 0.6;
            //font-size: 1.8vw;
        }
    }
}

.blog1 {
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 1.3vw;
    margin-top: 2%;

    .blog {
        &-img {
            img {
                flex: 1 0 50%;
                width: 100%;
            }
        }

        &-body {
            flex: 1 0 50%;
        }
    }
}

@media screen and (max-width: 768px) {
    .blog {
        img {
            border-radius: 6px;
        }

        &-body {
            &-btn-title {
                &-btn-date {
                    .button {
                        @include mobile_body_S12_light;
                        padding: 2px 5px;
                    }

                    &-date {
                        font-size: 1.6vw;
                    }
                }

                &-title {
                    font-size: 2.8vw;
                }
            }

            &-content {
                font-size: 2.2vw;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .blog {
        gap: 3.75vw;

        &-body {
            &-btn-title {
                gap: 3.66vw;

                &-btn-date {
                    .button {
                        font-size: 3.75vw;
                        padding: 1.5vw 3vw;
                    }

                    &-date {
                        font-size: 3.75vw;
                    }
                }

                &-title {
                    @include mobile_heading_h3-20;
                }
            }

            &-content {
                @include mobile_subTitle_S14;
            }
        }
    }

    .blog1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 3.75vw;

        // .blog {
        //     &-img {
        //         img {
        //             flex: 1 0 50%;
        //         }
        //     }

        //     &-body {
        //         flex: 1 0 50%;
        //     }
        // }
    }
}