@import '../../../global/style.scss';

.spinner {
    display: grid;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;

    &-loading {
        width: 50px;
        height: 50px;
        border: 10px solid #f3f3f3;
        border-top: 10px solid #383636;
        border-radius: 50%;
        animation: spinner 1.5s linear infinite;
    }

    // .smallSize {
    //     width: 15px;
    //     height: 15px;
    //     border: 4px solid #f3f3f3;
    //     border-top: 4px solid #383636;
    //     border-radius: 50%;
    // }
}

.spinnerSmall {
    &-loading {
        width: 15px;
        height: 15px;
        border: 4px solid #f3f3f3;
        border-top: 4px solid #383636;
        border-radius: 50%;
        animation: spinner 1.5s linear infinite;
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 768px) {
    .spinner {
        &-loading {
            width: 30px;
            height: 30px;
            border: 6px solid #f3f3f3;
            border-top: 6px solid #383636;
        }
    }
}

@media screen and (max-width: 500px) {
    .spinner {
        &-loading {
            width: 20px;
            height: 20px;
            border: 4px solid #f3f3f3;
            border-top: 4px solid #383636;
        }
    }
}