@import '../../global/style.scss';

.payment {
    display: flex;
    flex-direction: column;
    gap: 3.33vw;
    padding: 3.12vw 6.8vw;
    align-items: center;

    &-paywrap {
        width: 100%;

        &-title {
            text-align: center;
            margin-bottom: 10px;
            @include desktop_body_s;
        }

        &-divider {
            text-align: center;
            margin: 10px;
            @include desktop_body_s;
        }

        &-cardButton{
            text-align: center;
            
            cursor: pointer;
            background: #182335;
            padding: 8px 8px;
            display: flex;
            justify-content: center;
            border-radius: 4px;
            gap: 6px;

            .stripeLoading {
                width: 48px;
                animation-name: prolong;
                animation-duration: 10s;
                animation-iteration-count: infinite;
            }

            @keyframes prolong {
                100% {
                    width: 10%;
                }
            }

            .button {
                border-radius: 4px;
            }
        }
    }

    &-alert {
        display: block;
        position: fixed;
        z-index: 1000;
        width: 46vw;
        height: fit-content;
        background: $bg_color;
        color: $error_color;
        right: 27vw;
        top: -150vh;
        transition: all 0.4s;
        border-radius: 10px;
        padding: 20px;
        text-align: center;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .active {
        //top: 20vh;
        background: $display_color;
        cursor: pointer;
    }

    &-header {
        text-align: center;

        &-title {
            @include desktop_heading_h3;
        }

        &-subtitle {
            @include desktop_body_ss;
            color: rgba(24, 35, 37, 0.72);
        }
    }

    &-progress {
        img {
            width: 57.9vw;
        }
    }

    &-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        //width: 100%;
        gap: 1.66vw;

        &-left {
            flex: 33%;
            padding: 1.66vw 2.22vw;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &-up {
                position: relative;

                &-photo {
                    border: 1.88787px solid #F9F9F9;
                    border-radius: 10.6979px;
                    width: 63.9%;
                }

                &-doc {
                    width: 24.6%;
                    position: absolute;
                    bottom: -12%;
                    right: 26%;
                }
            }

            &-down {
                display: flex;
                flex-direction: column;
                gap: 6px;
                align-items: center;

                &-text {
                    @include desktop_body_s;
                    color: #191D23;
                }

                &-control {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 16px;
                    @include desktop_body_ss;

                    img {
                        width: 40px;
                    }

                    cursor: pointer;
                }
            }
        }

        &-right {
            flex: 68%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 2.7vw;
            gap: 2.2vw;
            white-space: nowrap;
            background: $gray_first_color;
            border-radius: 0.83vw;

            &-title {
                @include desktop_subTitle_L;
                letter-spacing: -0.01em;
                font-feature-settings: 'cpsp' on;
                color: $display_color;
            }

            &-item-group {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 1.3vw;
                width: 100%;


                &-item {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 0.27vw;

                    width: 100%;

                    &-content {
                        width: 100%;
                        padding: 16px;
                        border-radius: 8px;
                        white-space: nowrap;
                        min-width: max-content;
                        background-color: rgba($main_color, 0.1);
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        width: 100%;
                        align-items: center;

                        @include desktop_body_m;
                        display: flex;
                        align-items: center;
                        letter-spacing: -0.408px;
                        color: $body_color;

                        &-left {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                        }

                        &-right {
                            background: #1F8498;
                            border-radius: 100px;
                            padding: 4px 8px;
                            color: $bg_color;
                        }
                    }
                }
            }

            &-button {
                text-align: center;
                width: 100%;
                box-shadow: 0px 1px 2px rgba(38, 110, 255, 0.05);
                border-radius: 12px;
                background: gray;
            }
        }

        &-detail {
            padding: 1.66vw;
            display: flex;
            flex-direction: row;
            align-items: start;
            gap: 1.11vw;
            @include desktop_body_m;
            color: $display_color;
            justify-content: flex-start;

            &-photos {
                width: 140px;
            }

            &-photo {
                display: none;
                justify-content: right;
                
                &-right {
                    
                }
            }

            .showPhoto {
                display: flex;
            }

            &-options {
                legend {
                    @include desktop_subTitle_S20;
                }
                &-title{
                    @include desktop_body_S19;
                }
                &-item {

                    line-height: normal;
                    margin-bottom: 12px;

                    input[type="radio"] {
                        vertical-align: top;
                        margin-top: 8px;
                    }

                    &-label {
                        position: relative;
                        padding-left: 5px;
                        text-indent: 0;
                        display: inline-block;

                        &-type {
                            @include desktop_body_s_bold;
                        }

                        .oldPrice {
                            text-decoration: line-through;
                        }

                        &-price {
                            color: $display_color;
                            padding-right: 5px;

                            &-newPrice {
                                color: red;
                            }
                        }

                        .readOnly {
                            @include desktop_body_s_bold;
                        }

                        &-middle {
                            @include desktop_subTitle_L;
                            display: flex;
                            flex-direction: row;
                            gap: 20px;
                            align-items: center;


                            &-button {
                                @include desktop_subTitle_L_24;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                height: 40px;
                                width: 40px;
                                border-style: solid;
                                border-color: $display_color;
                                border-radius: 20px;
                                border-width: 0px;
                                color: $display_color;
                                background-color: $gray_second_color;
                            }

                            &-quantity {
                                @include desktop_subTitle_L;
                            }
                        }       
                        
                        select {
                            margin-right: 5px;
                            border: none;
                        }
                    }
                    // label {
                    //     text-align: end;
                    // }
                }
            }

            img {
                width: 64px;
            }


            // &-link {
            //     text-decoration: underline;
            //     cursor: pointer;
            // }

            // &-item {
            //     border: solid;
            //     border-radius: 10px;
            //     margin: 5px;
            // }
        }

        form {
            min-width: 320px;
            width: 50vw;
        }
    }
}

@media screen and (max-width: 768px) {
    .payment {
        &-progress {
            img {
                width: 79.9vw;
            }
        }

        &-body {
            display: flex;
            justify-content: space-between;
            //width: 100%;
            gap: 1.66vw;

            &-left {
                flex: 33%;
                padding: 1.66vw 2.22vw;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                &-up {
                    position: relative;

                    &-photo {
                        border: 1.88787px solid #F9F9F9;
                        border-radius: 10.6979px;
                        width: 63.9%;
                    }

                    &-doc {
                        width: 24.6%;
                        position: absolute;
                        bottom: -12%;
                        right: 26%;
                    }
                }

                &-down {
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                    align-items: center;

                    &-text {
                        @include desktop_body_s;
                        color: #191D23;
                    }

                    &-control {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 16px;
                        @include desktop_body_ss;

                        img {
                            width: 40px;
                        }

                        cursor: pointer;
                    }
                }
            }

            &-right {
                flex: 68%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 2.7vw;
                gap: 2.2vw;
                white-space: nowrap;
                background: $gray_first_color;
                border-radius: 0.83vw;

                &-title {
                    @include desktop_subTitle_L;
                    letter-spacing: -0.01em;
                    font-feature-settings: 'cpsp' on;
                    color: $display_color;
                }

                &-item-group {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 1.3vw;
                    width: 100%;


                    &-item {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        gap: 0.27vw;

                        width: 100%;

                        &-content {
                            padding: 11px 16px;

                            @include mobile_body_S12_light;
                        }
                    }
                }

                &-button {
                    text-align: center;
                    width: 100%;
                    box-shadow: 0px 1px 2px rgba(38, 110, 255, 0.05);
                    border-radius: 12px;
                    //background: #1F8498;
                }

            }

            &-detail {
                padding: 1.66vw;
                display: flex;
                flex-direction: row;
                gap: 1.11vw;
                @include desktop_body_m;
                color: $display_color;

                img {
                    width: 64px;
                }

                span {
                    @include desktop_body_s;
                }

                &-link {
                    text-decoration: underline;
                }
            }
        }
    }
}

@media screen and (max-width: 540px) {
    .payment {
        gap: 2vw;
        padding: 0 0;

        &-progress {
            img {
                width: 99.9vw;
            }
        }

        &-header {
            &-title {
                @include mobile_heading_h3-20;
            }
        }

        &-body {
            width: 100%;
            flex-direction: column;
            align-items: center;

            &-detail {
                //width: 100%;
                flex-direction: column;

                &-options-title{
                    @include mobile_body_S16;
                }

                &-photos {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                }

                &-photo {
                    width: 60px;

                    &-left {
                        width: 30px;
                        //z-index: -10;
                    }
                }

            }

            &-left {
                width: 100%;

                &-up {
                    display: flex;
                    flex-direction: column;

                    &-doc {
                        right: 8%;
                    }
                }

                &-down {
                    margin-top: 50px;
                }
            }

            &-right {
                width: 100%;

                &-item-group {
                    &-item {
                        &-content {
                            font-size: 10px;
                        }
                    }

                }
                &-button {
                    text-align: center;
                    width: 100%;
                    box-shadow: 0px 1px 2px rgba(38, 110, 255, 0.05);
                    border-radius: 12px;
                }
            }

            form {
                min-width: 320px;
                width: 90vw;
            }
        }
    }
}