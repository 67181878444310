@import '../../global/style.scss';

.popularDocuments {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;

    padding: 0px 2% 2% 2%;
    margin-top: 75px;

    &-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        &-title {
            @include desktop_heading_h1;

            letter-spacing: -0.01em;
            font-feature-settings: 'cpsp' on;

            color: $display_color;
        }

        &-control {
            display: flex;
            align-items: center;
            gap: 4%;
            background-color: $gray_first_color;
            border-radius: 12px;
            padding: 15px 24px;

            input {
                border: 0px;
                outline: 0px;
                background-color: $gray_first_color;

                @include desktop_body_m;
            }
        }

        .active {
            border: 1px solid $gray_fourth_color;
        }
    }

    &-empty {
        @include desktop_heading_h3;
        background-color: $gray_first_color;
        padding: 40px;
        border-radius: 24px;
        text-align: center;
        width: 100%;
    }

    &-content {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 2%;
        width: 100%;
    }

    &-footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 12px;
        margin-top: 100px;

        img {
            width: 56px;
            height: 56px;
        }

        &-item {
            @include desktop_subTitle_L;
            text-align: center;
            width: 40px;
            height: 33px;
        }

        .disabled {
            color: $gray_fourth_color;
        }
    }
}

@media screen and (max-width: 870px) {
    .popularDocuments {
        gap: 30px;
        padding: 0px 3.5% 3.5% 3.5%;

        &-header {
            &-title {
                @include mobile_heading_h2-24;

                letter-spacing: -0.01em;
                font-feature-settings: 'cpsp' on;

                color: $display_color;
            }

            &-control {
                padding: 9px 14px;

                input {
                    border: 0px;
                    outline: 0px;
                    background-color: $gray_first_color;

                    @include mobile_body_S12_light;
                }

                img {
                    width: 14px;
                    height: 14px;
                }
            }
        }

        &-empty {
            @include mobile_heading_h2-24;
            background-color: $gray_first_color;
            padding: 20px;
            border-radius: 12px;
        }

        &-content {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 2%;

        }

        &-footer {
            margin-top: 60px;
            gap: 8px;

            img {
                width: 52px;
                height: 52px;
            }

            &-item {
                @include mobile_subTitle_S18;
                height: 27px;
            }
        }
    }
}

@media screen and (max-width: 426px) {
    .popularDocuments {
        padding: 0px 5% 5% 5%;
        gap: 16px;

        &-header {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;

            &-title {
                @include mobile_heading_h2-24;

                letter-spacing: -0.01em;
                font-feature-settings: 'cpsp' on;

                color: $display_color;
            }

            &-control {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 4%;
                background-color: $gray_first_color;
                border-radius: 12px;
                padding: 9px 14px;

                input {
                    border: 0px;
                    outline: 0px;
                    background-color: $gray_first_color;

                    @include mobile_body_S12;
                }

                img {
                    width: 14px;
                    height: 14px;
                }
            }
        }

        &-empty {
            @include mobile_heading_h3-20;
            background-color: $gray_first_color;
        }

        &-content {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 0.5%;

            .doc {
                &:nth-child(n+6) {
                    display: none;
                }
            }
        }

        &-footer {
            gap: 1.25vw;

            img {
                width: 16.25vw;
            }

            &-item {
                width: 12.5vw;
            }
        }
    }
}