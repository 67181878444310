@import '../../global/style.scss';

.header {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    height: 75px;
    padding: 0.8% 2.7%;
    background-color: $bg_color;
    //z-index: 998;
    align-items: center;
    
    &-left {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;
    }

    &-mid {
        display: flex;
        flex-direction: row;
        align-items: center;
        white-space: nowrap;    
        height: 28px;

        .disabled {
            display: none;
        }

        &-btn-group {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 60%;
            justify-content: space-between;
            gap: 15px;
            font-size: 16px;
            
            a {
                text-align: center;
                font-size: 16px;
                cursor: pointer;
            }

            .selected {
                color: $body_color;
            }

            select {
                border: none;
                background-color: white;
                font-size: 18px;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
            }
        }
    }

    &-right {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10%;
        white-space: nowrap;
        margin-right: 15px;

        &-btn-hider {
            display: none;
            cursor: pointer;
        }

        &-btn-group {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 2%;
            font-size: 16px;
        }

        &-wrapper {
            cursor: pointer;
        }
    }

    &-disable-background {
        display: none;
    }

    .mobile-menu {
        display: none;
    }
}

@media screen and (max-width: 500px) {
    .header {
        height: 40px;

        &-left {    
            .logo {
                img {
                    height: 27px;
                }
            }
        }
    }
}