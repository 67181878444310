@import '../../../global/style.scss';

.sessions {
    display: flex;
    justify-content: center;

    &-button {
        padding: 10px;
        border-radius: 7px;
        background-color: $secondary_color;
        cursor: pointer;
    }

    &-body {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &-profile {
            text-align: end;
            a {
                color: blue;
                cursor: pointer;
                padding-left: 10px;
            }
        }

        &-title {
            text-align: center;
            @include desktop_body_s_bold;
        }

        &-data {
            width: 100%;
            padding-top: 10px;
            padding-bottom: 20px;
        }
    }
}