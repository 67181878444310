@import '../../global/style.scss';

.order-success {
    display: flex;
    flex-direction: column;
    gap: 2.4vw;
    padding: 3.12vw 6.8vw 6.12vw 6.8vw;

    &-loading {
        position: relative;
        //width: 10.54vw;
        align-self: center;
        @include desktop_subTitle_L;

        &-container {
            width: 100%;
            padding: 10px 0px;

            &-animation {
                width: 0%;
                height: 10px;
                background: #1f8498;
                border-radius: 2.08vw;
                animation-name: prolong;
                animation-duration: 30s;
                animation-iteration-count: infinite;
            }

            &-text {
                padding: 10px 0px;
            }

            @keyframes prolong {
                100% {
                    width: 100%;
                }
            }
        }
}

    &-panel {
        display: flex;
        flex-direction: column;
        align-self: center;
        gap: 24px;
        width: 48.6vw;

        background: $bg_color;
        border-radius: 12px;
        position: relative;

        &-alert {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            background: rgba(5, 174, 93, 0.1);
            border-radius: 16px;
            padding: 16px 16px 18px;

            div {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                gap: 8px;
            }
        }

        &-body {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 5.48vw;

            &-left {
                display: flex;
                flex-direction: column;
                gap: 32px;
                //width: 51.2%;

                &-up {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;

                    &-title {
                        @include desktop_subTitle_L_32;
                        color: $display_color;
                    }

                    &-content {
                        @include desktop_body_s;
                        color: rgba($display_color, 0.9);

                        a {
                            padding-left: 6px;
                            position: relative;
                            z-index: 10;
                            color: blue;
                            cursor: pointer;
                            text-decoration: underline;
                        }

                        ol {
                            @include desktop_body_s;
                            color: $body_color;
                            list-style-type: decimal;
                        }

                        li {
                            @include desktop_body_s_bold;
                            color: $body_color;
                            list-style-type: decimal;
                            margin-bottom: 10px;
                        }
                    }

                    .button {
                        border-radius: 12px;
                        width: fit-content;
                        z-index: 10;
                    }
                }

                &-down {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 1.42vw;
                    background: #F9F9F9;
                    border: 1px solid #DDDDDD;
                    border-radius: 17px;
                    width: 100%;
                    padding: 20px 40px;
                    @include desktop_body_s;

                    img {
                        width: 47.4%;
                    }
                }
            }

            &-img {
                width: 37.5%;
                margin-right: -5%;
            }
        }

        &-img {
            position: absolute;
            width: inherit;
            left: 0vw;
            opacity: 0;
            animation-name: fadeIn;
            animation-duration: 3s;
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@media screen and (max-width: 900px) {
    .order-success {
        &-panel {
            padding: 2.68vw, 10.75vw;
            // gap: 44px;
            width: 67.74vw;
        }
    }
}

@media screen and (max-width: 600px) {
    .order-success {
        flex-direction: column;
        gap: 10vw;

        &-loading {
            @include mobile_subTitle_S18;
        }

        &-panel {
            // padding: 6.25vw, 5vw;
            // gap: 24px;
            width: 90vw;

            &-body {
                &-left {
                    width: 100%;

                    &-down {
                        padding: 20px 16px;
                    }
                }

                &-img {
                    display: none;
                }
            }
        }
    }
}