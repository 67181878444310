@import '../../global/style.scss';

.step-content2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0%;
    padding: 3% 2%;
    margin-bottom: 8.33vw;

    &-img {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            width: 75.15%;
        }
    }

    &-alert {
        display: block;
        position: fixed;
        z-index: 1000;
        width: 46vw;
        height: fit-content;
        background: $bg_color;
        color: $error_color;
        right: 27vw;
        top: -150vh;
        transition: all 0.4s;
        border-radius: 10px;
        padding: 20px;
        text-align: center;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .active {
        top: 20vh;
    }

    &-card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 2.7vw;
        gap: 1.2vw;
        background: $gray_first_color;
        border-radius: 0.83vw;

        &-delete {
            align-self: flex-end;
            position: absolute;
            z-index: 1;
            width: 56px;
            height: 56px;
            margin: 0.55vw;
            cursor: pointer;
        }

        &-content {
            position: relative;
            width: 100%;

            &-disable-background {
                display: none;
                flex-direction: column;
                justify-content: center;
                gap: 0.5vw;
                align-items: center;
                background: rgba(24, 35, 37, 0.4);
                backdrop-filter: blur(10px);
                width: 100%;
                height: 100%;
                position: absolute;
                border-radius: 12px;
                @include desktop_body_s;
                color: $bg_color;
                font-size: 1.2vw;

                &-line {
                    position: relative;
                    width: 18.54vw;

                    &-1 {
                        position: absolute;
                        width: 100%;
                        height: 0.41vw;
                        background: #FFFFFF;
                        opacity: 0.32;
                        border-radius: 2.08vw;
                    }

                    &-2 {
                        width: 0%;
                        height: 0.41vw;
                        background: #FFFFFF;
                        border-radius: 2.08vw;
                        animation-name: analyze;
                        animation-duration: 1s;
                        animation-iteration-count: infinite;
                    }

                    @keyframes analyze {
                        100% {
                            width: 18.54vw;
                        }
                    }
                }
            }

            &-upload {
                border: 0px solid $gray_first_color;
                display: none;

                &-file {
                    width: 100%;
                    height: auto;
                    border-radius: 12px;
                    // aspect-ratio: 1 / 1;
                }
            }
        }

        &-placeholder {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            padding: 9.8vw 11.3vw;
            background: $bg_color;
            border-radius: 0.83vw;

            &-content {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                gap: 2.6vw;

                &-img {
                    width: 82.3%;
                }

                &-button {
                    text-align: center;
                    width: 100%;
                    font-size: 1.1vw;
                    padding: 1.3vw;
                }
            }
        }

        &-analyze-result {
            display: none;
            flex-direction: column;
            align-items: flex-start;
            gap: 1.38vw;
            width: 100%;

            &-title {
                @include desktop_heading_h3;
                letter-spacing: -0.01em;
                font-feature-settings: 'cpsp' on;
                font-size: 2.22vw;
                color: $display_color;
            }

            &-content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 0.55vw;

                &-item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 0.55vw;

                    &-img {
                        width: 28px;
                        height: 28px;
                    }

                    &-text {
                        @include desktop_body_s;
                    }
                }
            }
        }

        &-button {
            text-align: center;
            width: 100%;
            font-size: 1.1vw;
            padding: 1.3vw;
        }

    }

    &-file-upload {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .step-content2 {
        &-img {
            img {
                width: 95%;
            }
        }

        &-card {
            &-delete {
                width: 40px;
                height: 40px;
            }

            &-content {
                &-disable-background {
                    @include mobile_subTitle_S14;
                    font-size: 2.2vw;

                    &-line {
                        width: 23.54vw;

                        &-1 {
                            height: 0.8vw;
                            border-radius: 2.08vw;
                        }

                        &-2 {
                            height: 0.8vw;
                            border-radius: 2.08vw;
                        }

                        @keyframes analyze {
                            100% {
                                width: 23.54vw;
                            }
                        }
                    }
                }
            }

            &-placeholder {
                &-content {
                    &-img {
                        width: 61.5%;
                    }

                    &-button {
                        @include mobile_body_S12;
                    }
                }
            }

            &-analyze-result {
                &-title {
                    font-size: 2.6vw;
                }

                &-content {
                    &-item {
                        &-img {
                            width: 20px;
                            height: 20px;
                        }

                        &-text {
                            @include mobile_subTitle_S14;
                        }
                    }
                }
            }

            &-button {
                @include mobile_body_S12;
            }

        }
    }
}

@media screen and (max-width: 500px) {
    .step-content2 {
        display: flex;
        flex-direction: column-reverse;
        align-items: stretch;
        gap: 2vw;

        &-card {
            &-content {
                &-disable-background {
                    @include mobile_subTitle_S14;
                    font-size: 3.8vw;

                    &-line {
                        width: 48.54vw;

                        &-1 {
                            height: 1.41vw;
                            border-radius: 2.08vw;
                        }

                        &-2 {
                            height: 1.41vw;
                            border-radius: 2.08vw;
                        }

                        @keyframes analyze {
                            100% {
                                width: 48.54vw;
                            }
                        }
                    }
                }
            }

            &-placeholder {
                &-content {
                    &-img {
                        width: 41.5%;
                    }

                    &-button {
                        padding: 2.75vw;
                    }
                }
            }

            &-analyze-result {
                &-title {
                    font-size: 20px;
                }
            }

            &-button {
                padding: 2.75vw;
            }

        }

        &-alert {
            width: 80vw;
            right: 10vw;
            @include mobile_subTitle_S14;
        }
    }
}