@import '../../../global/style.scss';

.step-line {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2%;
    padding: 1% 2%;

    &-img {
        img {
            width: 100%;
            height: 4px;
        }
    }
}