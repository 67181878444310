@import '../../global/style.scss';

.blog-page {
    gap: 32px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &-error {
        width: 100%;
        margin-top: 50px;
        margin-bottom: 50px;
        @include desktop_subTitle_L;
        display: flex;
        justify-content: center;
    }

    &-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &-title {
            width: 100%;
            @include desktop_heading_h1;

            letter-spacing: -0.01em;
            font-feature-settings: 'pnum' on, 'lnum' on, 'kern' off;

            color: $display_color;
        }
    }

    &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.7vw;

        &-blog-group {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            gap: 2%;
            flex-wrap: wrap;

            .blog {
                flex: 1 0 30%;

                // &:nth-child(4) {
                //     flex: 0 1 100%;
                // }
            }
        }
    }

    .dontShow {
        display: none;
    }

    &-footer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 12px;
        margin-top: 100px;
        width: 100%;

        img {
            width: 56px;
            height: 56px;
        }

        &-item {
            @include desktop_subTitle_L;
            text-align: center;
            width: 40px;
            height: 33px;
        }

        .disabled {
            color: $gray_fourth_color;
        }
    }

}

@media screen and (max-width: 870px) {
    .blog-page {
        gap: 30px;
        padding: 0px 3.5% 3.5% 3.5%;

        &-header {
            &-title {
                @include mobile_heading_h1-38;

                letter-spacing: -0.01em;
                font-feature-settings: 'pnum' on, 'lnum' on, 'kern' off;

                color: $display_color;
            }
        }

        &-content-blog-group {
            .blog {
                flex: 1 0 40%;
            }
        }

        &-footer {
            margin-top: 60px;
            gap: 8px;

            img {
                width: 52px;
                height: 52px;
            }

            &-item {
                @include mobile_subTitle_S18;
                height: 27px;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .blog-page {
        padding: 0px 5% 5% 5%;
        gap: 16px;

        &-content-blog-group {
            grid-template-columns: repeat(1, 1fr);

            .blog {
                flex: 1 0 60%;
            }
        }


        &-footer {
            gap: 1.25vw;
            margin-top: 30px;

            img {
                width: 16.25vw;
            }

            &-item {
                width: 12.5vw;
            }
        }
    }
}