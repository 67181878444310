@import "../../global/style.scss";

.image-with-text {
  display: flex;
  flex-direction: row; 
  align-items: center; 
}
.image-with-block{
  display: flex;
  flex-direction: row; 
  flex-direction: column;
}
.image-with-text .text-content {
  flex: 1; 
  padding-right: 20px; 
}

.dErrors-img {
  max-width: 100%; 
  height: 300px;
  width: 300px;

}

.dErrors {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;

  &-title {
    @include desktop_heading_h2;
    letter-spacing: -0.01em;
    font-feature-settings: "cpsp" on;
    color: $display_color;
  }

  &-text {
    @include desktop_body_S19;
    color: $body_color;

    span.link {
      color: $main_color;
      text-decoration: underline;
    }

    span.bold {
      font-weight: 200%;
    }

    span.italic {
      font-style: italic;
    }
  }

  &-group {
    &-title {
      @include desktop_heading_h3;
      letter-spacing: -0.01em;
      font-feature-settings: "cpsp" on;
      color: $display_color;
    }

    &-text {
      @include desktop_body_S19;
      color: $body_color;

      span.link {
        color: $main_color;
        text-decoration: underline;
      }

      span.bold {
        font-weight: 200%;
      }

      span.italic {
        font-style: italic;
      }
    }
  }
}
.model-section {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  max-height: 320px;
}

.model-image {
  flex: 0 0 auto;
  width: 240px; 
  height: auto;
  margin-right: 10px; 
  border-radius: 7px;
}
@media screen and (max-width: 768px) {
  .image-with-text {
    flex-direction: column; 
    align-items: flex-start; 
  }

  .image-with-text .text-content {
    padding-right: 0; 
    margin-bottom: 20px; 
  }

  .image-with-text .dErrors-img {
    order: -1; 
    margin-top: -15px; 
  }
  .dErrors-img {
   

    max-width: 100%; 
    height: 200px;
    width: 200px;
  }
  .model-image{
    padding: 5px 5px;
    max-width: 100%; 
    height: 200px;
    width: 150px;
  }
  .image-with-block .model-section {
    order: -1; 
    margin-top: -15px; 
  }
  .dErrors {
    &-title {
      @include mobile_heading_h2-24;
      letter-spacing: -0.01em;
      font-feature-settings: "cpsp" on;
      color: $display_color;
    }

    &-text {
      @include mobile_subTitle_S14;
      color: $body_color;
    }

    &-group {
      &-title {
        @include mobile_heading_h3-20;
        letter-spacing: -0.01em;
        font-feature-settings: "cpsp" on;
        color: $display_color;
      }

      &-text {
        @include mobile_subTitle_S14;
        color: $body_color;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  
  .image-with-text {
    display: flex;
    flex-direction: column; 
    align-items: flex-start; 
  }

  .text-content {
    padding-right: 0; 
    margin-bottom: 20px; 
  }

  .dErrors-img {
    max-width: 100%; 
    height: 200px;
    width: 200px;
  }

  .model-section {
    overflow-x: scroll;
    overflow-y: hidden;
    margin-bottom: 15px;
    
  }
  
  .model-image {
    width: 135px; 
    height: 180px;
    margin-right: 5px; 
  }
  
}




