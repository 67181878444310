@import "../../global/style.scss";

.howitworks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.22vw;
  //padding: 0px 8.3% 7.2% 8.3%;
  padding-bottom: 7%;

  &-title {
    @include desktop_heading_h2;

    letter-spacing: -0.01em;
    font-feature-settings: "cpsp" on;

    color: $display_color;
  }

  &-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1%;

    &-item {
      background-color: $tone_second_color;
      border-radius: 12px;
      //position: relative;
      padding: 30px 24px;

      &-badge {
        @include desktop_body_l;
        position: absolute;
        top: 8px;
        left: 8px;
        background-color: $display_color;
        color: $bg_color;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 46px;
        height: 46px;
      }

      &-image {
        width: 100%;
        margin-top: 25px;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          border-radius: 8px;
          width: 71.7%;
        }
      }

      &-title {
        @include desktop_subTitle_L;
        letter-spacing: -0.01em;
        font-feature-settings: "cpsp" on;
        color: $display_color;
      }

      &-desc {
        @include desktop_body_s;
        color: $body_color;
        margin-top: 8px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .howitworks {
    //padding: 0px 3.5% 3.5% 3.5%;
    padding-bottom: 3.5%;

    &-title {
      @include mobile_heading_h2-24;
    }

    &-content {
      grid-template-columns: repeat(2, 1fr);

      &-item {
        // &:last-child {
        //     width: 202%;
        // }

        &-badge {
          @include mobile_subTitle_S18;
          width: 40px;
          height: 40px;
        }

        &-title {
          @include mobile_heading_h3-20;
        }

        &-desc {
          @include mobile_subTitle_S14;
        }
      }
    }
  }
}

@media screen and (max-width: 426px) {
  .howitworks {
    //padding: 0px 5% 5% 5%;
    padding-bottom: 5%;

    &-title {
      margin-top: 60px;
      margin-bottom: 24px;
    }

    &-content {
      grid-template-columns: repeat(1, 1fr);

      &-item {
        //padding: 0;

        &:last-child {
          width: 100%;
        }
      }
    }
  }
}
