@import '../../../global/style.scss';

.doc {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    gap: 20px;

    background-color: $gray_first_color;
    border-radius: 12px;

    width: 100%;

    &-img {
        text-align: center;
        width: 100%;
        padding: 0px 2.9vw;
    }

    &-title {
        @include desktop_subTitle_L;
        letter-spacing: -0.01em;
        font-feature-settings: 'cpsp' on;
        color: $display_color;

        text-align: center;
    }
}

@media screen and (max-width: 870px) {
    .doc {
        padding: 30px 8px;

        &-title {
            @include mobile_body_S12_medium;
        }
    }
}

@media screen and (max-width:426px) {
    .doc {
        padding: 40px;

        &-title {
            @include mobile_subTitle_S18;
        }
    }
}