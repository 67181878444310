@import '../../global/style.scss';

.ablog {
        //padding: 40px 40px 0vw 18.75vw;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 5.4vw;

    &-share {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        position: absolute;
        left: 2.77vw;
        @include desktop_body_s;
        color: $display_color;
    }

    &-left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2%;
        width: 60.52vw;

        &-blog {
            width: 100%;

            .blog-body-btn-title-title {
                @include desktop_heading_h2;
                letter-spacing: -0.01em;
                font-feature-settings: 'cpsp' on;
                color: $display_color;
            }
        }

        &-fastlink {
            display: none;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;

            &-title {
                @include desktop_heading_h3;
                letter-spacing: -0.01em;
                font-feature-settings: 'cpsp' on;
                color: $display_color;
            }

            &-body {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 5px;

                @include desktop_body_l;
                color: $main_color;
                text-decoration: underline;
            }
        }

        &-body {
            // display: flex;
            // flex-direction: column;
            // align-items: flex-start;
            // gap: 36px;
            width: 100%;

            // &-img {
            //     width: 100%;
            //     border-radius: 12px
            // }

            img {
                width: 100%;
                border-radius: 12px
            }

            // &-text {
            //     @include desktop_body_S19;
            //     color: $body_color;

            //     span.link {
            //         color: $main_color;
            //         text-decoration: underline;
            //     }

            //     span.bold {
            //         font-weight: 200%;
            //     }

            //     span.italic {
            //         font-style: italic;
            //     }
            // }

            // &-group {
            //     &-title {
            //         @include desktop_heading_h3;
            //         letter-spacing: -0.01em;
            //         font-feature-settings: 'cpsp' on;
            //         color: $display_color;
            //     }

            //     &-text {
            //         @include desktop_body_S19;
            //         color: $body_color;

            //         span.link {
            //             color: $main_color;
            //             text-decoration: underline;
            //         }

            //         span.bold {
            //             font-weight: 200%;
            //         }

            //         span.italic {
            //             font-style: italic;
            //         }
            //     }
            // }

            // &-alert {
            //     box-sizing: border-box;
            //     display: flex;
            //     flex-direction: row;
            //     align-items: center;
            //     padding: 1.38vw 1.38vw 1.38vw 2.77vw;
            //     gap: 10px;

            //     background: $gray_first_color;
            //     border-left: 4px solid $main_color;

            //     @include desktop_body_l;
            //     color: $display_color;
            // }
        }
    }

    &-right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 72px;
        width: 26.52vw;

        &-fastlink {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;

            &-title {
                @include desktop_heading_h3;
                letter-spacing: -0.01em;
                font-feature-settings: 'cpsp' on;
                color: $display_color;
            }

            &-body {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 5px;

                @include desktop_body_l;
                color: $main_color;
                text-decoration: underline;
            }
        }

        &-share {
            display: none;
            flex-direction: column;
            align-items: flex-start;
            padding: 12px 16px;
            gap: 8px;
            width: 100%;

            background: $gray_first_color;
            border-radius: 8px;

            @include mobile_subTitle_S18;
            letter-spacing: -0.01em;
            font-feature-settings: 'cpsp' on;

            color: $display_color;

            &-group {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: flex-start;
                gap: 12px;
            }
        }

        &-body {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 32px;
            width: 100%;

            &-title {
                @include desktop_heading_h3;

                letter-spacing: -0.01em;
                font-feature-settings: 'cpsp' on;
            }

            &-blog {
                width: 100%;

                .blog-body-btn-title-title {
                    @include desktop_subTitle_L;
                    letter-spacing: -0.01em;
                    font-feature-settings: 'cpsp' on;
                    color: $display_color;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .ablog {
        //padding: 34px 40px 0vw 13.03vw;

        &-left {
            &-blog {
                .blog-body-btn-title-title {
                    @include mobile_heading_h2-24;
                    letter-spacing: -0.01em;
                    font-feature-settings: 'cpsp' on;
                    color: $display_color;
                }
            }

            &-fastlink {
                gap: 16px;

                &-title {
                    @include mobile_heading_h3-20;
                    letter-spacing: -0.01em;
                    font-feature-settings: 'cpsp' on;
                    color: $display_color;
                }

                &-body {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 5px;

                    @include mobile_subTitle_S14_bold;
                    color: $main_color;
                    text-decoration: underline;
                }
            }


            &-body {
                &-text {
                    @include mobile_subTitle_S14;
                    color: $body_color;
                }

                &-group {
                    &-title {
                        @include mobile_heading_h3-20;
                        letter-spacing: -0.01em;
                        font-feature-settings: 'cpsp' on;
                        color: $display_color;
                    }

                    &-text {
                        @include mobile_subTitle_S14;
                        color: $body_color;
                    }
                }

                &-alert {
                    @include mobile_subTitle_S18;
                    color: $display_color;
                }
            }
        }

        &-right {
            gap: 30px;

            &-fastlink {
                gap: 16px;

                &-title {
                    @include mobile_heading_h3-20;
                    letter-spacing: -0.01em;
                    font-feature-settings: 'cpsp' on;
                    color: $display_color;
                }

                &-body {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 5px;

                    @include mobile_subTitle_S14_bold;
                    color: $main_color;
                    text-decoration: underline;
                }
            }

            &-body {
                gap: 16px;

                &-title {
                    @include mobile_heading_h3-20;

                    letter-spacing: -0.01em;
                    font-feature-settings: 'cpsp' on;
                    color: $display_color
                }

                &-blog {
                    .blog-body-btn-title-title {
                        @include mobile_subTitle_S18;
                        letter-spacing: -0.01em;
                        font-feature-settings: 'cpsp' on;
                        color: $display_color;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .ablog {
        //padding: 20px 5vw 0vw 5vw;
        flex-direction: column;

        &-share {
            display: none;
        }

        &-left {
            width: 100%;

            &-fastlink {
                margin-top: 10px;
                display: flex;
            }
        }

        &-right {
            width: 100%;

            &-fastlink {
                display: none;
            }

            &-share {
                display: flex;
            }
        }
    }
}