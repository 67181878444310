@import '../../global/style.scss';

.howtomake {
    display: flex;
    flex-direction: column;
    gap: 2.22vw;
    padding: 0px 15.27vw 2vw 15.27vw;

    &-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: center;

        &-title {
            @include desktop_heading_h2;

            letter-spacing: -0.01em;
            font-feature-settings: 'cpsp' on;

            color: $display_color;
        }
    }

    &-content {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 2%;

        &-item {
            background-color: $main_color;
            border-radius: 12px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 10px;
            position: relative;
            animation-name: slide;
            animation-duration: 1s;

            &:not(:first-child) {
                display: none;
            }

            &-img {
                align-self: center;
                width: 50%;
                border-radius: 12px;
            }

            &-content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 2.77vw 2.77vw 0vw 0vw;
                gap: 10px;
                color: $bg_color;

                &-up {
                    &-title {
                        @include desktop_heading_h3;
                        letter-spacing: -0.01em;
                        font-feature-settings: 'cpsp' on;
                        color: $bg_color;
                    }

                    &-desc {
                        @include desktop_body_s;
                        color: $gray_second_color;
                    }
                }

                &-control {
                    display: flex;
                    gap: 16px;
                    width: 64px;
                    position: absolute;
                    bottom: 2.77vw;
                }
            }

        }
    }

    @keyframes slide {
        0% {
            opacity: 30%;
        }

        100% {
            opacity: 100%;
        }
    }
}

@media screen and (max-width: 970px) {
    .howtomake {
        padding: 0px 8.27vw 2vw 8.27vw;

        &-header {
            &-title {
                @include mobile_heading_h1-h2;

                letter-spacing: -0.01em;
                font-feature-settings: 'cpsp' on;

                color: $display_color;
            }
        }

        &-content {
            &-item {
                &-content {
                    &-up {
                        &-title {
                            @include mobile_heading_h2-24;
                            letter-spacing: -0.01em;
                            font-feature-settings: 'cpsp' on;
                            color: $bg_color;
                        }

                        &-desc {
                            @include mobile_body_S16;
                            color: $gray_second_color;
                            font-size: 2.05vw;
                        }
                    }

                    &-control {
                        img {
                            width: 6vw;
                        }
                    }
                }

            }
        }
    }
}

@media screen and (max-width: 500px) {
    .howtomake {
        &-content {
            &-item {
                flex-direction: column;

                &-img {
                    width: 100%;
                }

                &-content {
                    padding: 0px 24px 24px 24px;

                    &-up {
                        &-desc {
                            @include mobile_body_S16;
                            color: $gray_second_color;
                        }
                    }

                    &-control {
                        position: initial;

                        // bottom: 2.77vw;
                        img {
                            width: 64px;
                        }
                    }
                }

            }
        }
    }
}