@import '../../global/style.scss';

.popular-doc {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 100px;

    padding: 0px 2% 2% 2%;
    margin-top: 75px;

    &-link {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 2px;
        gap: 12px;

        @include desktop_body_s;
        color: $body_color;

        .selected {
            color: $gray_fourth_color;
        }
    }

    &-hero {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-self: stretch;
        gap: 20px;

        &-left {
            flex: 50%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 32px;

            &-body {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 16px;

                &-title {
                    @include desktop_heading_h1;
                    letter-spacing: -0.01em;
                    font-feature-settings: 'pnum' on, 'lnum' on, 'kern' off;
                    color: $display_color;
                }

                &-content {
                    @include desktop_body_l;
                    color: $body_color;
                }
            }

            &-buton {}
        }

        &-right {
            flex: 50%;
            background: $gray_first_color;
            border-radius: 24px;
            width: 100%;

            padding: 6.94vw 13.33vw;

            img {
                width: 100%;
                border-radius: 6px;
            }
        }

    }

    &-requirements {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-self: stretch;
        gap: 20px;

        &-left {
            flex: 50%;
            padding: 8.88vw;

            &-img {
                width: 100%;
            }
        }

        &-right {
            flex: 50%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 32px;

            &-up {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 16px;

                &-title {
                    @include desktop_heading_h2;
                    letter-spacing: -0.01em;
                    font-feature-settings: 'cpsp' on;
                    color: $display_color;

                    span {
                        color: $main_color;
                    }
                }

                &-content {
                    @include desktop_body_m;
                    color: $body_color;
                }
            }

            &-down {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 16px;
            }
        }
    }

    &-faq {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-self: stretch;
        gap: 20px;

        &-left {
            flex: 50%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 32px;

            &-up {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 16px;

                &-title {
                    @include desktop_heading_h2;
                    letter-spacing: -0.01em;
                    font-feature-settings: 'cpsp' on;
                    color: $display_color;
                }

                &-content {
                    @include desktop_body_m;
                    color: $body_color;
                }
            }

            &-down {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 16px;
            }
        }

        &-right {
            flex: 50%;

            &-img {
                width: 100%;
            }
        }
    }

    &-popular {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
        width: 100%;

        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            &-title {
                @include desktop_heading_h2;
                letter-spacing: -0.01em;
                font-feature-settings: 'cpsp' on;
                color: $display_color;
            }

            &-control {
                display: flex;
                gap: 4%;
            }
        }

        &-content {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 2%;
            width: 100%;
        }

        &-footer {
            display: none;
        }
    }
}

@media screen and (max-width: 870px) {
    .popular-doc {
        gap: 30px;
        padding: 0px 3.5% 3.5% 3.5%;

        &-link {
            @include mobile_subTitle_S14;
            color: $body_color;
        }

        &-hero {
            gap: 5px;

            &-left {
                gap: 16px;

                &-body {
                    gap: 12px;

                    &-title {
                        @include mobile_heading_h1-h2;
                        letter-spacing: -0.01em;
                        font-feature-settings: 'pnum' on, 'lnum' on, 'kern' off;
                        color: $display_color;
                    }

                    &-content {
                        @include mobile_subTitle_S14;
                        color: $body_color;
                    }
                }

                &-button {
                    @include mobile_body_S12_medium;
                }
            }

        }

        &-requirements {
            &-left {
                padding: 8.88vw;
            }

            &-right {
                &-up {
                    &-title {
                        @include mobile_heading_h2-24;
                        letter-spacing: -0.01em;
                        font-feature-settings: 'cpsp' on;
                        color: $display_color;
                    }

                    &-content {
                        @include mobile_subTitle_S14;
                        color: $body_color;
                    }
                }
            }
        }

        &-faq {
            &-left {
                &-up {
                    &-title {
                        @include mobile_heading_h2-24;
                        letter-spacing: -0.01em;
                        font-feature-settings: 'cpsp' on;
                        color: $display_color;
                    }

                    &-content {
                        @include mobile_subTitle_S14;
                        color: $body_color;
                    }
                }
            }
        }

        &-popular {
            &-header {
                &-title {
                    @include mobile_heading_h2-24;
                    letter-spacing: -0.01em;
                    font-feature-settings: 'cpsp' on;
                    color: $display_color;
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .popular-doc {
        padding: 0px 5% 5% 5%;
        gap: 16px;

        &-link {
            @include mobile_body_S12_light;
            color: $body_color;
        }

        &-hero {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 22px;
        }

        &-requirements {
            display: flex;
            flex-direction: column-reverse;
            align-items: flex-start;
            gap: 24px;
        }

        &-faq {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
        }

        &-popular {
            align-items: center;

            &-header {
                &-title {
                    @include mobile_heading_h2-24;
                    letter-spacing: -0.01em;
                    font-feature-settings: 'cpsp' on;
                    color: $display_color;
                }

                &-control {
                    display: none;
                }
            }

            &-content {
                grid-template-columns: repeat(1, 1fr);

                .doc {
                    &:not(&:first-child) {
                        display: none;
                    }
                }
            }

            &-footer {
                display: flex;
                gap: 4px;
                margin-top: 16px;

                img {
                    width: 40px;
                }
            }
        }

    }
}