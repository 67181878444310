@import '../../global/style.scss';

.step {
    padding: 0% 2.76%;
    margin-top: 75px;
}

@media screen and (max-width: 768px) {
    .step {}
}

@media screen and (max-width: 426px) {
    .step {
        margin-top: 46px;
    }
}