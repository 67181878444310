@import "../../global/style.scss";

.q-and-a {
  align-items: flex-start;
  gap: 36px;

  display: flex;
  flex-direction: column;

  background-color: $display_color;
  border-radius: 12px;
  padding: 30px 24px;
  //   margin: 140px 26.73vw;
  //   gap: 2.22vw;
  //   display: flex;
  //   flex-direction: column;
  //   gap: 36px;

  &-title {
    @include desktop_heading_h2;
    letter-spacing: -0.01em;
    font-feature-settings: "cpsp" on;
    color: white;
  }

  &-text {
    @include desktop_body_S19;
    color: white;

    span.link {
      color: $main_color;
      text-decoration: underline;
    }

    span.bold {
      font-weight: 200%;
    }

    span.italic {
      font-style: italic;
    }
  }

  &-group {
    &-title {
      @include desktop_heading_h3;
      letter-spacing: -0.01em;
      font-feature-settings: "cpsp" on;
      color: white;
    }

    &-text {
      @include desktop_body_S19;
      color: white;

      span.link {
        color: $main_color;
        text-decoration: underline;
      }

      span.bold {
        font-weight: 200%;
      }

      span.italic {
        font-style: italic;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .q-and-a {
    margin: 30px 0;

    &-title {
      @include mobile_heading_h2-24;
      letter-spacing: -0.01em;
      font-feature-settings: "cpsp" on;
      color: white;
    }

    &-text {
      @include mobile_subTitle_S14;
      color: white;
    }

    &-group {
      &-title {
        @include mobile_heading_h3-20;
        letter-spacing: -0.01em;
        font-feature-settings: "cpsp" on;
        color: white;
      }

      &-text {
        @include mobile_subTitle_S14;
        color: white;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .q-and-a {
    margin: 30px 0;
  }
}
