@import '../../../global/style.scss';

.horizontal-slider {
    width: 100%;
    max-width: 500px;
    margin: auto;
}

.horizontal-thumb {
    cursor: pointer;
    position: absolute;
    z-index: 100;
    background: $bg_color;
    border: 2px solid $display_color;
    border-radius: 100%;
    display: block;
}

.horizontal-thumb.active {
    background-color: $bg_color;
}

.horizontal-track {
    position: relative;
    background: $bg_color;
}

.horizontal-track.horizontal-track-0 {
    background: $display_color;
}

.horizontal-slider .horizontal-track {
    height: 5px;
    border-radius: 4px;
}

.horizontal-slider .horizontal-thumb {
    top: -8px;
    width: 21px;
    height: 21px;
    outline: none;
}

@media screen and (max-width: 768px) {
    .horizontal-slider .horizontal-thumb {
        top: -6px;
        width: 17px;
        height: 17px;
    }
}