@import '../../global/style.scss';

.price {
    display: flex;
    flex-direction: column;
    margin-top: 75px;
    gap: 4.16vw;
    padding: 3.12vw 10.76vw;

    &-up {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;

        &-title {
            @include desktop_heading_h1;

            letter-spacing: -0.01em;
            font-feature-settings: 'pnum' on, 'lnum' on, 'kern' off;
            color: $display_color;
            align-self: center;
            text-align: center
        }

        &-content {
            @include desktop_body_l;
            color: $display_color;
            align-self: center;
            text-align: center;
        }
    }

    &-down {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        width: 100%;

        .price-panel {
            flex: 50%;
            width: 100%;
        }
    }

    &-disable-background {
        display: block;
        width: 100vw;
        height: 100vh;
        background-color: gray;
        position: fixed;
        z-index: 999;
        left: 0px;
        top: 0px;
        background: rgba($display_color, 0.4);

        &-img {
            position: absolute;
            display: none;
            height: -webkit-fill-available;
            width: -webkit-fill-available;
        }
    }

    &-dialog {
        display: block;
        position: fixed;
        z-index: 1000;
        width: 46vw;
        height: fit-content;
        background: $bg_color;
        right: 27vw;
        top: -150vh;
        transition: all 0.4s;
        border-radius: 24px;

        .close-btn {
            position: absolute;
            right: 16px;
            top: 16px;
        }

        &-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 4.87vh 2.77vw;
            gap: 3.9vh;
            isolation: isolate;

            &-title {
                @include desktop_heading_h3;
                letter-spacing: -0.01em;
                font-feature-settings: 'cpsp' on;
                color: $display_color;
                // font-size: 2.22vw;

                &-discount {
                    color: $main_color;
                }
            }

            &-content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 2.43vh;
                width: 100%;

                &-item {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 0.48vh;
                    width: 100%;

                    &-label {
                        @include desktop_body_s;
                        // font-size: 1.1vw;
                    }

                    &-control {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 4%;
                        border-radius: 8px;
                        padding: 1.38vw 0px;
                        justify-content: center;

                        .input {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            gap: 4%;
                            border-radius: 8px;
                            padding: 1.38vw 16px;
                            justify-content: space-between;

                            input {
                                border: 0px;
                                outline: 0px;
                                width: 100%;

                                @include desktop_body_m;
                                // font-size: 1.25vw;
                            }
                        }

                    }

                    &-button-group {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        gap: 0.83vw;

                        .button {
                            flex: 50%;
                        }
                    }
                }
            }

            &-button {
                width: 100%;
            }
        }

        &-result {
            display: none;
            flex-direction: column;
            align-items: flex-start;
            padding: 4.87vh 2.77vw;
            gap: 3.9vh;
            isolation: isolate;

            &-title {
                @include desktop_heading_h3;
                letter-spacing: -0.01em;
                font-feature-settings: 'cpsp' on;
                color: $display_color;
            }

            &-button {
                width: 100%;
            }
        }

    }

    .active {
        top: 10vh;
    }
}

@media screen and (max-width: 768px) {
    .price {
        &-up {
            &-title {
                @include mobile_heading_h1-h2;
            }

            &-content {
                @include mobile_subTitle_S14;
            }
        }

        &-dialog {
            width: 60vw;
            right: 20vw;

            &-content {
                &-title {
                    @include mobile_heading_h2-24;
                }

                &-content {
                    &-item {
                        &-label {
                            @include mobile_subTitle_S14;
                        }

                        &-control {
                            input {
                                @include mobile_subTitle_S14;
                            }
                        }

                        &-button-group {
                            .button {
                                flex: 50%;
                                @include mobile_subTitle_S14_bold_b;
                            }
                        }
                    }
                }

                &-button {
                    @include mobile_subTitle_S14_bold_b;
                }
            }

            &-result {
                &-title {
                    @include mobile_heading_h2-24;
                }

                &-button {
                    @include mobile_subTitle_S14_bold_b;
                }
            }

        }
    }
}

@media screen and (max-width: 600px) {
    .price {
        &-down {
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 100%;
        }

        &-dialog {
            width: 90vw;
            right: 5vw;
        }
    }
}