@import '../../global/style.scss';

.popular-documents {
    display: flex;
    flex-direction: column;
    gap: 2.22vw;
    padding: 0px 8.3% 7.2% 8.3%;

    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-title {
            flex: 77.5%;
            @include desktop_heading_h2;

            letter-spacing: -0.01em;
            font-feature-settings: 'cpsp' on;

            color: $display_color;
        }

        &-control {
            flex: 22.5%;
            display: flex;
            align-items: center;
            gap: 4%;
            background-color: $gray_first_color;
            border-radius: 12px;
            padding: 15px 24px;
            justify-content: space-between;

            input {
                border: 0px;
                outline: 0px;
                background-color: $gray_first_color;

                @include desktop_body_m;
            }
        }
    }

    &-content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2%;

        &-more {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 20px;

            background-color: $gray_first_color;
            border-radius: 12px;
            padding: 40px;
            width: 100%;

            &-img {
                text-align: center;

                &-arrow {
                    width: 56px;
                    height: 56px;
                }
            }

            &-title {
                @include desktop_subTitle_L;
                letter-spacing: -0.01em;
                font-feature-settings: 'cpsp' on;
                color: $display_color;

                text-align: center;
            }

        }
    }

    &-footer {
        display: none;
    }
}

@media screen and (max-width: 870px) {
    .popular-documents {
        padding: 0px 3.5% 3.5% 3.5%;

        &-header {
            &-title {
                flex: 71%;
                @include mobile_heading_h2-24;
            }

            &-control {
                flex: 29%;

                input {
                    @include mobile_subTitle_S12;
                }
            }
        }

        &-content {
            &-more {
                padding: 30px 8px;
                gap: 12px;

                &-img {
                    &-arrow {
                        width: 30px;
                        height: 30px;
                    }
                }

                &-title {
                    @include mobile_body_S12_medium;
                }
            }
        }
    }
}

@media screen and (max-width: 426px) {
    .popular-documents {
        padding: 0px 5% 5% 5%;

        &-header {
            flex-direction: column;

            &-title {
                @include mobile_heading_h2-24;
            }

            &-control {
                align-items: center;
                padding: 9px 14px;
                margin-top: 12px;
                width: 100%;

                input {
                    @include mobile_subTitle_S12;
                }
            }
        }

        &-content {
            display: grid;
            grid-template-columns: repeat(1, 1fr);

            .doc {
                &:not(:first-child) {
                    display: none;
                }
            }

            &-more {
                padding: 20px 40px;

                &-img {
                    &-arrow {
                        width: 40px;
                        height: 40px;
                    }
                }

                &-title {
                    @include mobile_subTitle_S18;
                }
            }
        }

        &-footer {
            display: block;

            .button {
                font-size: 20px;
            }
        }
    }
}