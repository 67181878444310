@import '../../global/style.scss';

.monitor {
    display: flex;
    justify-content: center;
    min-height: 600px;
    align-items: baseline;

    &-button {
        padding: 10px;
        border-radius: 7px;
        background-color: $secondary_color;
        cursor: pointer;
    }

    &-body {
        width: 100%;
        //min-height: 600px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &-head {
            display: flex;
            flex-direction: row;
            align-items: end;

            &-label {
                margin-right: 7px;
            }

            &-left {
                //width: 20%;
            }

            .yellow {
                background-color: yellow;
            }

            .red {
                background-color: red;
            }

            &-right {
                flex-grow: 1;
                
                //position: fixed;
                //right: 0px;
                text-align: end;

                a {
                    color: blue;
                    cursor: pointer;
                    padding-left: 10px;
                }
            }
        }

        &-title {
            text-align: center;
            @include desktop_body_s_bold;
        }

        &-data {
            width: 100%;
            padding-top: 10px;
            padding-bottom: 20px;
        }
    }
}